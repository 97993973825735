<div class="container">
  <div id="buttons" class="mb-2 d-flex flex-wrap">
    <button mat-raised-button [routerLink]="['/content/resident/dashboard']">{{ "PAGES.BACK" | translate }}</button>
    <button mat-raised-button (click)="sendRequestToMail()">{{ "FORMS.REPORT.BUTTONS.SEND_REQUEST" | translate }}</button>
    <ng-container *ngIf="energyConsult?.state?.name === 'FilledIn'">
      <button mat-raised-button (click)="approveRequest()">{{ "FORMS.REPORT.BUTTONS.APPROVE" | translate }}</button>
      <button mat-raised-button (click)="dialogService.show(disapproveDialog)">{{ "FORMS.REPORT.BUTTONS.DISAPPROVE" | translate }}</button>
    </ng-container>
  </div>
  <div style="border-left: var(--darker-outline-color) 2px solid" class="row mb-5" *ngFor="let questionnaire of questionnaires">
    <div class="col-md">
      <h2>{{ "FORMS.REPORT.QUESTIONNAIRE.TITLE" | translate : { title: questionnaire.title } }}</h2>
      <h4>{{ "FORMS.REPORT.QUESTIONNAIRE.DESCRIPTION" | translate : { description: questionnaire.description } }}</h4>
    </div>
    <div class="mt-3">
      <ng-container [ngTemplateOutlet]="formTemplate" [ngTemplateOutletContext]="{ data: questionnaire }"> </ng-container>
    </div>
  </div>
</div>

<ng-template #formTemplate let-data="data">
  <ng-container *ngIf="toQuestionnaire(data); let data">
    <form [formGroup]="returnFormGroup(data.id!)!">
      <div class="row" *ngFor="let question of data.questions">
        <div class="col-md-8">
          <div class="row mx-3 mb-5 text-break" [hidden]="returnFormGroup(data.id!)?.controls?.[question.id!]?.disabled ?? true">
            <p>
              <b>{{ question.name }}</b>
            </p>
            <p>
              <i>{{ question.description }}</i>
            </p>
            <p style="color: var(--brand-color)">
              <ng-container [ngSwitch]="question.questionType.prefix">
                <ng-container *ngSwitchCase="'sel'">
                  <b>{{ getSelectOption(question) }}</b>
                </ng-container>

                <ng-container *ngSwitchDefault>
                  <b>{{ question.answer?.answer }}</b>
                </ng-container>
              </ng-container>
            </p>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
</ng-template>

<ng-template #disapproveDialog>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md">
        <h3 class="title">{{ "FORMS.REPORT.DIALOGS.DISAPPROVE.TITLE" | translate }}</h3>
        <p>{{ "FORMS.REPORT.DIALOGS.DISAPPROVE.DESCRIPTION" | translate }}</p>
        <mat-form-field class="example-full-width w-100" appearance="fill">
          <mat-label>{{ "FORMS.REPORT.DIALOGS.DISAPPROVE.LABEL" | translate }}</mat-label>
          <textarea matInput [formControl]="disapprovalReason"></textarea>
          <app-error [control]="disapprovalReason"></app-error>
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="dialogService.close()">{{ "COMPONENTS.DIALOG.BACK" | translate }}</button>
        <button mat-raised-button color="primary" [disabled]="disapprovalReason.invalid" (click)="disapproveRequest()">{{ "COMPONENTS.DIALOG.SUBMIT" | translate }}</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #successfullyDisapprovedDialog>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md">
        <h3 class="title">{{ "FORMS.REPORT.DIALOGS.SUCCESSFULLY_DISAPROVED.TITLE" | translate }}</h3>
        <p>{{ "FORMS.REPORT.DIALOGS.SUCCESSFULLY_DISAPROVED.DESCRIPTION" | translate }}</p>
      </div>
    </div>
  </div>
</ng-template>
