import { AfterViewChecked, Component } from "@angular/core";
import { Router } from "@angular/router";
import { ApplicationService } from "../../../services/application.service";

@Component({
  selector: "app-screen-logged-out",
  templateUrl: "./logged-out.component.html",
  styleUrls: ["./logged-out.component.less"],
})
export class LoggedOutComponent implements AfterViewChecked {
  public constructor(public readonly applicationService: ApplicationService, public router: Router) {}
  ngAfterViewChecked() {
    if (this.applicationService.session.authenticated) {
      this.router.navigate(["/content"]);
    } else {
      if (localStorage.length > 0) {
        localStorage.clear();
      }
    }
  }
}
