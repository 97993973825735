import { Injectable } from "@angular/core";
import { Answer } from "../classes/flow/Questionnaire/Answer";
import { OperatorType } from "../classes/flow/Questionnaire/OperatorType";
import { QuestionType } from "../classes/flow/Questionnaire/QuestionType";
import { Validation } from "../classes/flow/Questionnaire/Validation";
import { GraphQLService } from "./graphql.service";

@Injectable({
  providedIn: "root",
})
export class QuestionService {
  public constructor(private readonly graphqlService: GraphQLService) {}

  /**
   * Inserts an answer for a question of a specific energy consult
   * @param answer The given answer
   */
  public async insertAnswerForQuestion(answer: Answer) {
    let result: any = await this.graphqlService.query(
      `mutation {
        addQuestionAnswer(questionAnswer: {
          questionId: ${answer.questionId}
          energyConsultId: ${answer.energyConsultId}
          answer: "${answer.answer}"
        }) {
          value {
            id
            changes {
              lastChange {
                userId
                time
              }
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
            }
          }
          messages{
            message
          }
        }
      }`
    );
    result = result.data.addQuestionAnswer.value;
    answer.changes = this.graphqlService.createChangesObject(result.changes);
    answer.id = result.id;
  }

  /**
   * Updates an answer for a question of a specific energy consult
   * @param answer The given answer
   */
  public async updateAnswerForQuestion(answer: Answer) {
    const result = await this.graphqlService.query(
      `mutation {
        updateQuestionAnswer(input: {
          id: ${answer.id}
          set: {
            answer: "${answer.answer}"
            changes: ${this.graphqlService.formatChangesObject(answer)}
          }
        }) {
          value {
            changes {
              lastChange {
                userId
                time
              }
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
            }
          }
          messages{
            message
          }
        }
      }`
    );

    answer.changes = this.graphqlService.createChangesObject(result.data.updateQuestionAnswer.value.changes);
  }

  /**
   * Delete all unused answers of the given reports for the specific energy consult
   * @param questionIds The id's of the questions
   * @param energyConsultId The id of the energy consult
   */
  public async deleteAllUnusedAnswers(questionIds: number[], energyConsultId: number) {
    await this.graphqlService.query(
      `mutation {
        deleteAllUnusedAnswers(energyConsultId: ${energyConsultId}, questionIds: [${questionIds}]) {
          value {
            id
          }
          messages{
            message
          }
        }
      }`
    );
  }

  public async getAllQuestionTypes(): Promise<QuestionType[]> {
    const res = await this.graphqlService.query(
      `query {
        questionTypes {
          value {
            id
            name
            prefix
            changes {
              lastChange {
                userId
                time
              }
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
            }
          }
        }
          messages{
            message
          }
      }
    }`
    );

    return res.data.questionTypes.value.map((questionType: any) => {
      return {
        id: questionType.id,
        name: questionType.name,
        prefix: questionType.prefix,
      };
    });
  }

  public async getAllOperatorTypes(): Promise<OperatorType[]> {
    const res = await this.graphqlService.query(
      `query {
        operatorTypes {
          value {
            id
            name
            operation
            changes {
              lastChange {
                userId
                time
              }
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
          }
        }
        messages{
          message
        }
      }
}`
    );

    return res.data.operatorTypes.value.map((operatorType: any) => {
      return {
        id: operatorType.id,
        name: operatorType.name,
        operation: operatorType.operation,
      };
    });
  }

  public async getAllValidations(): Promise<Validation[]> {
    const res = await this.graphqlService.query(
      `query {
        validations {
          value {
            id
            name
            changes {
              lastChange {
                userId
                time
              }
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
            }
          }
        } 
          messages{
            message
          }
      }
    }`
    );

    return res.data.validations.value.map((validation: any) => {
      return {
        id: validation.id,
        name: validation.name,
      };
    });
  }
}
