import { User } from "./impl/User";
import { StorageService } from "../../../services/storage.service";
import { UserService } from "../../../services/user.service";

export type RoleName = "guest" | "user" | "resident" | "coordinator" | "coach";

export interface Role {
  id: number;
  name: RoleName;
}

export class Session {
  public user?: User;
  public active = false;

  public get activeRole(): Role {
    return this.storageService.fetch<Role>("selectedRole") ?? {id: 0, name: "guest"};
  }

  public get authenticated() {
    return !!this.user;
  }

  public get hasSelectedRole() {
    return this.storageService.has("selectedRole");
  }

  constructor(private readonly storageService: StorageService, private readonly userService: UserService) {
  }

  public async setActiveRole(value: Role) {
    await this.userService.changeRole(value);
    this.storageService.store<Role>("selectedRole", value);
  }
}
