<div id="menuContainer">
  <div class="row d-flex justify-content-center align-items-center">
    <div id="closeContainer" class="col d-flex align-self-center">
      <span class="icon" (click)="hide()">
        <mat-icon>close</mat-icon>
      </span>
    </div>

    <div class="row">
      <div class="col">
        <ul *ngIf="items.length > 0">
          <li class="item d-flex" *ngFor="let item of items">
            <a (click)="navigate(item.href || item.route)">
              <span class="icon">
                <mat-icon>{{item.icon}}</mat-icon>
              </span>
              <span class="label">{{ item.label === "MUNICIPALITY" ? applicationService.municipalitySlogan : ("MENU.ITEMS." + item.label) | translate}}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div id="menuUnderlay" (click)="active = false"></div>
</div>
