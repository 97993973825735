import { HttpClient } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { registerLocaleData } from "@angular/common";

/* Custom Languages */
import netherlands from "@angular/common/locales/nl";
import english from "@angular/common/locales/en";

@NgModule({
  exports: [TranslateModule],
  providers: [],
  imports: [
    TranslateModule.forRoot({
      defaultLanguage: "nl",
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient): TranslateHttpLoader => new TranslateHttpLoader(http, "/assets/i18n/", ".json"),
        deps: [HttpClient],
      },
    }),
  ],
})
export class LanguageModule {
  private languages: unknown[];

  public constructor() {
    this.languages = [netherlands, english];
    this.initLanguages();
  }

  private initLanguages() {
    for (const lang of this.languages) {
      registerLocaleData(lang);
    }
  }
}
