import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { ApplicationService } from "../services/application.service";

@Injectable({ providedIn: "root" })
export class IsLoggedInGuard implements CanActivate {
  public constructor(private router: Router, public readonly applicationService: ApplicationService) {}

  canActivate(): boolean {
    if (!this.applicationService.session.authenticated) return true;

    this.router.navigate(["/content"]);
    return false;
  }
}
