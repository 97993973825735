import { Injectable } from "@angular/core";
import { MsalGuardConfiguration, MsalService } from "@azure/msal-angular";
import { GraphQLService } from "./graphql.service";
import { AccountInfo, RedirectRequest } from "@azure/msal-browser";
import { Resident } from "../classes/flow/session/impl/Resident";
import { Role } from "../classes/flow/session/Session";
import { User } from "../classes/flow/session/impl/User";
import { MutationResult } from "apollo-angular";
import { Language } from "../helpers/determineLanguage";
import { ResidentService } from "./resident.service";
import { CoachService } from "./coach.service";
import { CoordinatorService } from "./coordinator.service";
import { StorageService } from "./storage.service";
import { Coach } from "../classes/flow/session/impl/Coach";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private msalGuardConfig?: MsalGuardConfiguration;

  public constructor(
    private readonly graphqlService: GraphQLService,
    private readonly residentService: ResidentService,
    private readonly coachService: CoachService,
    private readonly coordinatorService: CoordinatorService,
    private readonly storageService: StorageService,
    public readonly authService: MsalService
  ) {}

  /**
   * Retrieves the account information from MsalService
   * @returns AccountInfo
   */
  public getActiveAccount(): AccountInfo {
    return this.authService.instance.getActiveAccount()!;
  }

  public async initialize(role?: Role): Promise<User> {
    const user = await this.getCurrentUser();
    return role ? await this.getUserByRole(role, user) : user;
  }

  private async getCurrentUser(): Promise<User> {
    const result = await this.graphqlService.query(
      `query {
        currentAccount {
          value {
            id
            email
            toegangDatumVanaf
            toegangDatumTM
            roles {
              id
              name
            }
            changes {
              lastChange {
                userId
                time
              }
              fullDetails{
                key
                value {
                  userId
                  time
                }
              }
            }
          }
          messages{
            message
          }
        }
      }`
    );

    const user = result.data["currentAccount"]["value"];
    return new User({
      id: user.id,
      email: user.email,
      accessStartingDate: user.toegangDatumVanaf,
      accessEndDate: user.toegangDatumTM,
      roles: user.roles.map((role: any) => {
        return {
          id: role.id,
          name: role.name,
        } as Role;
      }),
      changes: this.graphqlService.createChangesObject(user.changes),
    });
  }

  public async changeRole(role: Role) {
    await this.graphqlService.query(`
      mutation {
        changeRole(input: {activeRoleId: ${role.id}}) {
          messages {
            message
          }
        }
      }
    `);
  }

  /**
   * Gets a derived user object by a specific role, i.e. a Resident
   * @param role The role to fetch the data for
   * @param user The user to fetch the data for
   * @returns An object of one of the classes derived from User
   */
  public async getUserByRole(role: Role, user: User): Promise<User> {
    switch (role.name) {
      case "resident":
        return await this.residentService.getResidentByUser(user);
      case "coach":
        return (await this.coachService.getCoachByUser(user)).value!;
      case "coordinator":
        return await this.coordinatorService.getCoordinatorByUser(user);
    }
    return user;
  }

  /**
   * Returns the user with the specified id
   * @param id The id of the user
   * @returns Promise of the user
   */
  public async getUserById(id: number): Promise<User> {
    const user = new User({ id: id, email: "" });
    user.roles = await this.getUserRoles(user);
    return await this.getUserByRole(user.roles[0], user);
  }

  public async getUserRoles(user: User): Promise<Role[]> {
    const result = await this.graphqlService.query(
      `query {
        users {
          value (where: {id: {eq: ${user.id}}}){
            statistics {
              role {
                id
                name
              }
            }
            changes {
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
              lastChange {
                userId
                time
              }
            }
          }
          messages{
            message
          }
        }
      }`
    );
    return result.data["users"].value[0].statistics.map((statistic: any) => {
      const role = statistic.role;
      return {
        id: role.id,
        name: role.name,
      };
    });
  }

  // public async getUserAccessDates(user: User): Promise<Date[]> {
  //   const result = await this.graphqlService.query(
  //     `query getUserAccesDates{
  //       allUsers {
  //         value (where: {id: {eq: ${user.userId}}}){
  //           toegangDatumVanaf
  //           toegangDatumTM
  //         }
  //       }
  //     }`
  //   );
  //   return [result.data["allUsers"].value[0].toegangDatumVanaf, result.data["allUsers"].value[0].toegangDatumTM];
  // }

  /**
   * Gets data of all users
   * @returns An array of all users
   */
  public async getAllUsers(): Promise<User[]> {
    const result = await this.getAllUserData();

    return result.data["users"].value.map((user: any) => {
      return new User({
        id: user.id,
        email: user.email,
        roles: user.statistics.map((statistic: any) => {
          return {
            id: statistic.role.id,
            name: statistic.role.name,
          };
        }),
        statistics: user.statistics.map((statistic: any) => {
          return {
            requestCount: statistic.requestCount,
            role: {
              id: statistic.role.id,
              name: statistic.role.name,
            },
          };
        }),
        accessStartingDate: user.toegangDatumVanaf ? new Date(user.toegangDatumVanaf) : undefined,
        accessEndDate: user.toegangDatumTM ? new Date(user.toegangDatumTM) : undefined,
      });
    });
  }

  /**
   * Gets data of all users
   * @returns data of all users
   */
  private async getAllUserData(): Promise<MutationResult<any>> {
    return await this.graphqlService.query(
      `query {
        users {
          value {
            id
            email
            toegangDatumVanaf
            toegangDatumTM
            statistics {
              role {
                id
                name
              }
              requestCount
            }
          }
          messages{
            message
          }
        }
      }`
    );
  }

  /**
   * Updates the data of the given user
   * @param user The user to update
   */
  public async updateUser(user: User) {
    const table = user instanceof Resident ? "Resident" : user instanceof Coach ? "Coach" : "Coordinator";
    const result = await this.graphqlService.query(`
      mutation {
        update${table}(input: {
          id: ${user.id}
          set: {
            firstName: "${user.firstName}"
            lastName: "${user.lastName}"
            ${table === "Coach" ? `emailSubscription: ${(user as Coach).emailSubscription}` : ""}
            changes: ${this.graphqlService.formatChangesObject(user)}
            ${
  table == "Resident"
    ? `postalCode: "${(user as Resident).postalCode}"
              phoneNumber: "${(user as Resident).phoneNumber}"
              houseNumber: ${(user as Resident).houseNumber}
              ${(user as Resident).houseNumberSuffix ? `houseNumberSuffix: "${(user as Resident).houseNumberSuffix}"` : ""}`
    : ""
}
          }
        }) {
          value {
            id
            changes {
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
              lastChange {
                userId
                time
              }
            }
          }
          messages {
            message
          }
        }
      }`);
    user.changes = this.graphqlService.createChangesObject(result.data["update" + table].value.changes);
  }

  /**
   * Deletes the given user
   * @param user The user to delete
   */
  public async deleteUser(user: User) {
    const table = user instanceof Resident ? "Resident" : user instanceof Coach ? "Coach" : "Coordinator";
    const result = await this.graphqlService.query(`
      mutation {
        delete${table + "(" + table.toLocaleLowerCase() + "Id: " + user.id}) {
          value {
            toegangDatumTM
            changes {
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
              lastChange {
                userId
                time
              }
            }
          }
          messages{
            message
          }
        }
      }`);

    const value = result.data["delete" + table].value;
    user.changes = this.graphqlService.createChangesObject(value.changes);
    user.accessEndDate = new Date(value.toegangDatumTM);
  }

  /**
   * Reactivates the account of the given user
   * @param user The user
   */
  public async reactivateAccount(user: User) {
    if (user.accessEndDate && new Date() < user.accessEndDate) {
      const table = user instanceof Resident ? "Resident" : user instanceof Coach ? "Coach" : "Coordinator";
      user.accessEndDate = undefined;

      const result = await this.graphqlService.query(`
        mutation {
          update${table}(input: {
            id: ${user.id}
            set: {
              toegangDatumTM: ${null}
              changes: ${this.graphqlService.formatChangesObject(user)}
            }
          }) {
            value {
              changes {
                fullDetails {
                  key
                  value {
                    userId
                    time
                  }
                }
                lastChange {
                  userId
                  time
                }
              }
            }
            messages{
              message
            }
          }
        }`);
      user.changes = this.graphqlService.createChangesObject(result.data["update" + table].value.changes);
    }
  }

  /**
   * Activates the account of the given user
   * @param user The user to activate
   */
  public async activateAccount(user: User) {
    const table = user instanceof Resident ? "Resident" : user instanceof Coach ? "Coach" : "Coordinator";
    user.accessStartingDate = new Date();
    const result = await this.graphqlService.query(`
      mutation {
        update${table}(input: {
          id: ${user.id}
          set: {
            toegangDatumVanaf: "${user.accessStartingDate.toISOString()}"
            changes: ${this.graphqlService.formatChangesObject(user)}
          }
        }) {
          value {
            changes {
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
              lastChange {
                userId
                time
              }
            }
          }
          messages {
            message
          }
        }
      }`);
    user.changes = this.graphqlService.createChangesObject(result.data["update" + table].value.changes);
  }

  public async registerLanguageForUser(user: User, language: Language) {
    const table = user instanceof Resident ? "Resident" : user instanceof Coach ? "Coach" : "Coordinator";
    const result = await this.graphqlService.query(`
      mutation {
        update${table}(input: {
          id: ${user.id}
          set: {
            languageId: ${await this.getLanguageId(language)}
            changes: ${this.graphqlService.formatChangesObject(user)}
          }
        }) {
          value {
            changes {
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
              lastChange {
                userId
                time
              }
            }
          }
          messages{
            message
          }
        }
      }`);
    user.changes = this.graphqlService.createChangesObject(result.data["update" + table].value.changes);
  }

  public async getLanguageId(language: Language): Promise<number> {
    const result = await this.graphqlService.query(
      `query {
        languages {
          value(where: {code: {eq: "${language.toUpperCase()}"}}) {
            id
          }
          messages{
            message
          }
        }
      }`
    );
    return result.data.languages.value[0].id;
  }

  /**
   * Login the user
   */
  public login() {
    if (this.msalGuardConfig?.authRequest) {
      this.authService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  /**
   * Logs the current user out
   */
  public async logout() {
    await this.graphqlService.query(`
      mutation {
        logOff {
          messages {
            message
          }
        }
      }
    `);
    this.storageService.clear();
    this.authService.logoutRedirect();
  }
}
