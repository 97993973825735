import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "../../services/user.service";

@Component({
  selector: "app-login-failed",
  templateUrl: "./login-failed.component.html",
  styleUrls: ["./login-failed.component.less"],
})
export class LoginFailedComponent implements OnInit {
  countDown = 5;

  constructor(public userService: UserService, public router: Router) {}

  ngOnInit(): void {
    localStorage.clear();
    if (this.userService.authService.instance.getAllAccounts().length) {
      this.router.navigate(["content"]);
    } else {
      setInterval(() => {
        this.countDown--;
        if (this.countDown == 0) location.href = "/content";
      }, 1000);
    }
  }
}
