import { Role } from "../Session";
import { Entity } from "../../base/Entity";
import { Changes } from "../../base/Changes";

export type UserStatus = "New" | "Active" | "PendingDeleted" | "Deleted";
export const UserStatuses = ["New", "Active"];
export const UserRoles = ["resident", "coach", "coordinator"];

export interface UserRoleStatistic {
  requestCount?: number;
  role: Role;
}

export class User implements Entity {
  public userId?: number;
  public firstName?: string;
  public lastName?: string;
  public email: string;
  public roles: Role[];
  public accessStartingDate?: Date;
  public accessEndDate?: Date;
  public id?: number;
  public changes?: Changes;
  public statistics?: UserRoleStatistic[];

  public constructor({
    firstName,
    lastName,
    email,
    id,
    changes,
    roles = [],
    accessStartingDate,
    accessEndDate,
    statistics,
  }: {
    firstName?: string;
    lastName?: string;
    email: string;
    id?: number;
    changes?: Changes;
    roles?: Role[];
    accessStartingDate?: Date;
    accessEndDate?: Date;
    statistics?: UserRoleStatistic[];
  }) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.userId = id;
    this.roles = roles;
    this.accessStartingDate = accessStartingDate ? new Date(accessStartingDate) : undefined;
    this.accessEndDate = accessEndDate ? new Date(accessEndDate) : undefined;
    this.id = id;
    this.changes = changes;
    this.statistics = statistics;
  }

  public get active() {
    return !!this.email && this.status !== "PendingDeleted" && this.status !== "Deleted";
  }

  public get status() {
    const currDate = new Date();
    if (!this.accessStartingDate && !this.accessEndDate) {
      return "New";
    } else if (this.accessStartingDate && !this.accessEndDate) {
      return "Active";
    } else {
      return currDate > this.accessEndDate! ? "Deleted" : "PendingDeleted";
    }
  }

  public incomplete(): boolean {
    return this.active && !(this.firstName && this.lastName);
  }

  /**
   * Checks whether this user has the given Role
   * @param role The Role to check for
   * @returns True if the user has this role, false otherwise
   */
  public hasRole(role: Role): boolean {
    return this.roles.includes(role);
  }

  /**
   * Checks whether this user is deleted or pending to be deleted
   * @returns True if the user is deleted (or pending to be deleted), false otherwise
   */
  public isDeleted(): boolean {
    return this.status === "Deleted" || this.status === "PendingDeleted";
  }
}
