import { Host, Injectable, OnDestroy } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { APP_CONFIG } from "../../environments/environment";
import { RoleName, Session } from "../classes/flow/session/Session";
import { filter, Subscription } from "rxjs";
import { MenuComponent, MenuItem } from "../components/menu/menu";
import { APP_ORGANISATION } from "../../environments/environment.organisation";
import Logger from "../classes/Logger";
import menu from "../../assets/shared/menu/all.menu.json";
import menuCoach from "../../assets/shared/menu/coach.menu.json";
import menuResident from "../../assets/shared/menu/resident.menu.json";
import menuCoordinator from "../../assets/shared/menu/coordinator.menu.json";
import menuDeletedUser from "../../assets/shared/menu/deletedUser.menu.json";
import menuGuest from "../../assets/shared/menu/guest.menu.json";
import { getLanguage } from "../helpers/determineLanguage";
import { StorageService } from "./storage.service";
import { UserService } from "./user.service";
import { NavigationStart, Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class ApplicationService implements OnDestroy {
  private static readonly NAME: string = APP_ORGANISATION.ORGANISATION;
  private static readonly menuMap = new Map<RoleName, MenuItem[]>([
    ["coordinator", menuCoordinator.menu.items],
    ["coach", menuCoach.menu.items],
    ["resident", menuResident.menu.items],
  ]);

  // No suitable injection parameters
  public session = new Session(this.storageService, this.userService);
  public host?: Host;
  public subscriptions = new Map<string, Subscription[]>();
  public debug = !APP_CONFIG.production;
  public initialized = false;
  public loading = false;
  public blocked = false;
  public municipalityHomeURL: MenuItem = {
    href: APP_ORGANISATION.SOCIALS.LINKS.MAIN,
    route: "",
    label: "MUNICIPALITY",
    icon: "open_in_new",
    index: 0,
  };
  public menu: MenuItem[] = [...menuGuest.menu.items, this.municipalityHomeURL];
  public menuComponent!: MenuComponent;
  public municipalitySlogan = APP_ORGANISATION.SOCIALS.SLOGAN;

  public constructor(
    private readonly storageService: StorageService,
    private readonly userService: UserService,
    private readonly titleService: Title,
    private readonly translateService: TranslateService,
    private readonly router: Router
  ) {
    this.subscribe(
      "routeChanges",
      this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
        this.blocked = false;
      })
    );
  }

  /**
   * Starts the initialization of the application
   */
  //remove logging of jwt before pushing to dev
  public async initialize() {
    if (!this.initialized) {
      this.titleService.setTitle(ApplicationService.NAME);
      this.translateService.use(getLanguage() ?? "");

      const jwt = JSON.parse(<string>localStorage.getItem(<string>Object.keys(localStorage).find((key) => key.includes("idtoken"))))?.secret;
      console.log(jwt);
      this.host = "Web";
      this.initialized = true;
    }
  }

  /**
   * Updates the title of the application
   * @param title The title
   */
  public updateTitle(title: string) {
    this.titleService.setTitle(`${ApplicationService.NAME} - ${title}`);
  }

  /**
   * Adds a subscription to be collected for auto-unsubscribe
   * @param key subscription key id
   * @param subscription one or multiple Subscriptions
   */
  public subscribe(key: string, ...subscription: Subscription[]) {
    try {
      this.subscriptions.set(key, [...(this.subscriptions.get(key) || []), ...subscription]);
    } catch (error) {
      Logger.error(`Unable to subscribe with key -> ${key}`);
      throw new Error(this.getErrorMessage(error));
    }
  }

  /**
   * Removes all subscriptions depending on key
   * @param key subscription key id
   */
  public unsubscribe(key: string) {
    if (this.debug) console.log(`DEBUG MODE: ${this.debug} [Component] Destroying id -> ${key}`);

    try {
      const subscriptions = this.subscriptions.get(key) || [];
      for (const subscription of subscriptions) subscription.unsubscribe();
    } catch (error) {
      Logger.error(`Unable to unsubscribe with key -> ${key}`);
      throw new Error(this.getErrorMessage(error));
    }
  }

  /**
   * @param error The error given
   * @returns The error as a string in case it is not
   */
  public getErrorMessage(error: unknown) {
    if (error instanceof Error) return error.message;
    return String(error);
  }

  /**
   * Enables the page loader
   */
  public loader() {
    this.loading = false;
  }

  /**
   * Initializes the menu
   */
  public initMenu() {
    if (this.session.user?.isDeleted()) {
      this.menu = menuDeletedUser.menu.items.concat(...menu.menu.items);
    } else {
      this.menu = ApplicationService.menuMap.get(this.session.activeRole.name)!.concat(this.municipalityHomeURL);
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribe("routeChanges");
  }
}
