<div id="allUserContainer">
  <div class="row">
    <div class="col-md">
      <h2>{{ "COMPONENTS.USERS.TITLE" | translate }}</h2>
    </div>
  </div>
  <button mat-raised-button [routerLink]="['/content/coordinator/new-coach']">{{ "COMPONENTS.USERS.ADD_USER_BUTTON" | translate }}</button>
  <div class="d-flex justify-content-around mt-4" [formGroup]="filterToggles">
    <mat-slide-toggle formControlName="statusToggle" (change)="usersTable.update()">{{ "COMPONENTS.USERS.STATUS_TOGGLE" | translate }}</mat-slide-toggle>
    <mat-slide-toggle formControlName="rolesToggle" (change)="usersTable.update()">{{ "COMPONENTS.USERS.ROLES_TOGGLE" | translate }}</mat-slide-toggle>
  </div>
  <div class="row">
    <div class="col-md">
      <app-table
        #usersTable
        id="USERS"
        [data]="users"
        [search]="true"
        [pageSizes]="[5, 10, 25]"
        [customFilter]="getFilterFunction()"
        [headers]="[
          { visualName: 'EMAIL', mappedTo: 'email' },
          { visualName: 'STATUS', resolver: displayStatus },
          { visualName: 'ROLE', resolver: displayRoles },
          { visualName: 'OPEN', link: { baseUrl: '/content/profile', params: ['userId'] } }
        ]"
      ></app-table>
    </div>
  </div>
</div>
