import { CommonModule } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  MsalModule,
  MSAL_INSTANCE,
  MSAL_GUARD_CONFIG,
  MsalService,
  MsalGuard,
  MsalBroadcastService,
  MsalRedirectComponent,
  MsalInterceptor,
  MSAL_INTERCEPTOR_CONFIG,
} from "@azure/msal-angular";
import { TableVirtualScrollModule } from "ng-table-virtual-scroll";
import { AppRoutingModule } from "./app-routing.module";
import { MSALInstanceFactory, MSALGuardConfigFactory, MSALInterceptorConfigFactory } from "./classes/MSAL";
import { BadgeFiltersComponent } from "./components/badge-filters/badge-filters";
import { DialogComponent } from "./components/dialog/dialog.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HeaderComponent } from "./components/header/header.component";
import { MenuComponent } from "./components/menu/menu";
import { EnergyConsultComponent } from "./components/energy-consult/energy-consult.component";
import { LanguageModule } from "./modules/language.module";
import { MaterialModule } from "./modules/material.module";
import { LocalDatePipe } from "./pipes/date.pipe";
import { ChooseRoleComponent } from "./screens/choose-role/choose-role.component";
import { AllQuestionnairesComponent } from "./screens/content/all-questionnaires/all-questionnaires.component";
import { QuestionnaireComponent } from "./screens/content/all-questionnaires/questionnaire/questionnaire.component";
import { CoachComponent } from "./screens/content/coach/coach.component";
import { CoachEnergyConsultComponent } from "./screens/content/coach/coach-energy-consult/coach-energy-consult.component";
import { ContentComponent } from "./screens/content/content";
import { CoordinatorComponent } from "./screens/content/coordinator/Coordinator.component";
import { NewCoachComponent } from "./screens/content/coordinator/new-coach/new-coach.component";
import { UserAllComponent } from "./screens/content/coordinator/user/all/all.component";
import { UsersComponent } from "./screens/content/coordinator/user/users.component";
import { ResidentComponent } from "./screens/content/resident/Resident.component";
import { DashboardComponent } from "./screens/content/shared/dashboard/dashboard";
import { ProfileComponent } from "./screens/content/shared/profile/profile.component";
import { Error_403Component } from "./screens/error/403/403";
import { Error_404Component } from "./screens/error/404/404";
import { ErrorComponent } from "./screens/error/error";
import { NewEnergyConsultComponent } from "./screens/guest/new-energy-consult/new-energy-consult.component";
import { NewQuestionnaireComponent } from "./screens/new-questionnaire/new-questionnaire.component";
import { RootComponent } from "./screens/root/root";
import { ApiService } from "./services/api.service";
import { ApplicationService } from "./services/application.service";
import { GraphQLService } from "./services/graphql.service";
import { EnergyConsultService } from "./services/energy-consult.service";
import { UserService } from "./services/user.service";
import { ReportComponent } from "./screens/report/report.component";
import { SnackbarService } from "./services/snackbar.service";
import { LoggedOutComponent } from "./screens/guest/logged-out/logged-out.component";
import { HasRoleGaurd } from "./guards/hasRole";
import { ViewReportComponent } from "./screens/view-report/view-report.component";
import { FAQComponent } from "./screens/faq/faq.component";
import { TableComponent } from "./components/table/table.component";
import { LoginFailedComponent } from "./screens/login-failed/login-failed.component";
import { QuestionnaireFormComponent } from "./components/questionnaire-form/questionnaire-form.component";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { CustomPaginator } from "./components/table/CustomPaginator";
import { RoleDirective } from "./directives/role-directive";
import { InputErrorComponent } from "./components/error/error.component";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { StateProgressBarComponent } from "./components/state-progress-bar/state-progress-bar.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { AsyncActionButtonComponent } from "./components/async-action-button/async-action-button.component";
import { CoachService } from "./services/coach.service";
import { CoachEnergyConsultService } from "./services/coach-energy-consult.service";
import { TimeRangePickerComponent } from "./components/time-range-picker/time-range-picker.component";

@NgModule({
  declarations: [
    RootComponent,
    ErrorComponent,
    Error_404Component,
    Error_403Component,
    ProfileComponent,
    RootComponent,
    HeaderComponent,
    NewEnergyConsultComponent,
    AllQuestionnairesComponent,
    QuestionnaireComponent,
    NewQuestionnaireComponent,
    MenuComponent,
    ContentComponent,
    CoachComponent,
    CoordinatorComponent,
    UsersComponent,
    UserAllComponent,
    EnergyConsultComponent,
    DialogComponent,
    ChooseRoleComponent,
    CoachEnergyConsultComponent,
    NewCoachComponent,
    ResidentComponent,
    QuestionnaireComponent,
    FooterComponent,
    DashboardComponent,
    LocalDatePipe,
    ReportComponent,
    BadgeFiltersComponent,
    LoggedOutComponent,
    TableComponent,
    ViewReportComponent,
    FAQComponent,
    LoginFailedComponent,
    QuestionnaireFormComponent,
    RoleDirective,
    InputErrorComponent,
    StateProgressBarComponent,
    AsyncActionButtonComponent,
    TimeRangePickerComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MaterialModule,
    MsalModule,
    FormsModule,
    ReactiveFormsModule,
    TableVirtualScrollModule,
    LanguageModule,
    NgxMaterialTimepickerModule,
    DragDropModule,
  ],
  providers: [
    ApplicationService,
    ApiService,
    UserService,
    EnergyConsultService,
    GraphQLService,
    HasRoleGaurd,
    SnackbarService,
    CoachService,
    CoachEnergyConsultService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: MatPaginatorIntl,
      useClass: CustomPaginator,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: "nl",
    },
  ],
  bootstrap: [RootComponent, MsalRedirectComponent],
})
export class AppModule {}
