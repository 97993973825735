import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { ApplicationService } from "../../services/application.service";
import { UserService } from "../../services/user.service";

export interface MenuItem {
  href?: string;
  route: string;
  label: string;
  icon: string;
  index: number;
}

@Component({
  selector: "app-menu",
  templateUrl: "./menu.html",
  styleUrls: ["./menu.less"],
})
export class MenuComponent {
  public active = false;

  @Input("items")
  public items: MenuItem[] = [];

  public constructor(public readonly applicationService: ApplicationService, public readonly userService: UserService, private readonly router: Router) {}

  /**
   * Hides the menu
   */
  public hide() {
    this.active = false;
  }

  /**
   * Shows the menu
   */
  public show() {
    this.active = true;
  }

  public navigate(link: string) {
    if (link) {
      if (link.includes("http")) {
        window.open(link, "_blank");
      } else {
        this.router.navigate([link]).then(() => {
          this.active = false;
        });
      }
    }
  }
}
