
<div class="container d-flex justify-content-center">
  <div class="row w-100">
    <div class="col flex-column text-center">
        <div class="col-md">
          <h2>{{ "COMPONENTS.ALL_QUESTIONNAIRES.TITLE" | translate }}</h2>
          <button mat-raised-button [routerLink]="['/content/coordinator/new-questionnaire']">{{ "COMPONENTS.ALL_QUESTIONNAIRES.ADD_QUESTIONNAIRE" | translate }}</button>
        </div>
        <div class="row">
          <div class="col-md">
            <app-table id="QUESTIONNAIRES" [data]="questionnaires" [search]="true" [pageSizes]="[5, 10, 25]"
              [headers]="[
                {visualName: 'TITLE', mappedTo: 'title'},
                {visualName: 'DESCRIPTION', mappedTo: 'description'},
                {visualName: 'SPECIALTIES', customTemplate: specialties},
                {visualName: 'OPEN', link: {baseUrl: '/content/coordinator/questionnaire/' , params: ['id']}},
                {visualName: 'PDF', tooltip: ('COMPONENTS.ALL_QUESTIONNAIRES.PDF.TOOLTIP' | translate), click: {icon: 'picture_as_pdf', clickHandler: openPdfFile}},
                {visualName: 'DELETE', tooltip: ('COMPONENTS.ALL_QUESTIONNAIRES.DELETE_QUESTIONNAIRE.TOOLTIP' | translate), click: {icon: 'delete', color: 'warn', clickHandler: openDeleteDialog}},
              ]"></app-table>
          </div>
        </div>
    </div>
  </div>
</div>

<ng-template #specialties let-questionnaire="data">
  <div mat-raised-button [matTooltip]="formatSpecialties(questionnaire.specialties)" class="align-middle" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">
    <mat-label>{{questionnaire.specialties?.length ? questionnaire.specialties[0].name : "COMPONENTS.ALL_QUESTIONNAIRES.NO_SPECIALTY" | translate}}</mat-label>
  </div>
</ng-template>

<ng-template #deleteDialog let-questionnaire="data">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md">
        <h3 class="title">{{ "COMPONENTS.ALL_QUESTIONNAIRES.DELETE_QUESTIONNAIRE.TITLE" | translate }}</h3>
        <p>{{ "COMPONENTS.ALL_QUESTIONNAIRES.DELETE_QUESTIONNAIRE.DESCRIPTION" | translate }}</p>
        <button mat-raised-button color="primary" (click)="dialogService.close()">{{ "COMPONENTS.DIALOG.BACK" | translate }}</button>
        <button mat-raised-button color="primary" (click)="deleteQuestionnaire(questionnaire); dialogService.close()">{{ "FORMS.DELETE" | translate }}</button>
      </div>
    </div>
  </div>
</ng-template>
