import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { firstValueFrom } from "rxjs";
@Component({
  selector: "app-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.less"],
})
export class FAQComponent implements OnInit {
  version!: string;
  constructor(public http: HttpClient) {}

  async ngOnInit() {
    try {
      this.version = (await firstValueFrom(this.http.get("assets/version.txt", { responseType: "text" as "json" }))) as string;
      const split = this.version.split("_");
      this.version = split[0].slice(0, 8) + "-" + split[1] + "-" + split[2] + "" + split[3];
    } catch (e) {
      this.version = "DEV_LOCAL";
    }
  }
}
