import { AfterViewInit, Component, OnDestroy, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { ApplicationService } from "../../../../services/application.service";
import { UserService } from "../../../../services/user.service";
import { v4 as generateUUID } from "uuid";
import { Coach } from "../../../../classes/flow/session/impl/Coach";
import { DialogService } from "../../../../services/dialog.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { SnackbarService } from "../../../../services/snackbar.service";
import { TranslateService } from "@ngx-translate/core";
import { EnergyConsult } from "../../../../classes/flow/request/EnergyConsult";
import { EnergyConsultService } from "../../../../services/energy-consult.service";
import { CoachEnergyConsultService } from "../../../../services/coach-energy-consult.service";
import { RequestStates } from "../../../../classes/flow/request/RequestStates";
import { CoachService } from "../../../../services/coach.service";
import { downloadFile } from "../../../../helpers/downloadFile";
import { ReportService } from "../../../../services/report.service";

@Component({
  selector: "app-coach-energy-consult",
  templateUrl: "./coach-energy-consult.component.html",
  styleUrls: ["./coach-energy-consult.component.less"],
})
export class CoachEnergyConsultComponent implements OnDestroy, AfterViewInit {
  private id: string = generateUUID();
  public energyConsult?: EnergyConsult;
  public isEditingDate = false;
  public formGroup = new FormGroup({
    date: new FormControl(new Date(), Validators.required),
    time: new FormControl("", Validators.required),
  });
  public rejectReasonControl = new FormControl("", Validators.required);

  public pdfSendDate?: string;
  public sendDateAvailable = false;

  public coaches?: any;
  public changeCoachControl = new FormControl();

  @ViewChild("pdfGenerateSuccess")
  private pdfGenerateSuccess!: TemplateRef<unknown>;

  public constructor(
    public readonly application: ApplicationService,
    private readonly translateService: TranslateService,
    private readonly energyConsultService: EnergyConsultService,
    public readonly user: UserService,
    private readonly route: ActivatedRoute,
    public readonly dialog: DialogService,
    private readonly snackbarService: SnackbarService,
    private readonly coachService: CoachService,
    private readonly coachEnergyConsultService: CoachEnergyConsultService,
    private readonly reportService: ReportService
  ) {}

  public ngAfterViewInit(): void {
    const route = this.route.params.subscribe(async (params: Params) => {
      await this.initEnergyConsult(params.id);
      this.initializeFormGroup();
      this.checkSendReportDate();
      this.setStreetName();
    });
    this.application.subscribe(this.id, route);
  }

  public ngOnDestroy(): void {
    this.application.unsubscribe(this.id);
  }

  public setEditingDate(isEditingDate: boolean): void {
    this.isEditingDate = isEditingDate;
  }

  /**
   * Initializes the form group of the date
   */
  private initializeFormGroup() {
    const appointmentDate = this.energyConsult?.appointmentDate;
    const today = new Date();
    this.formGroup.patchValue({
      date: appointmentDate && appointmentDate < today ? today : appointmentDate,
      time: appointmentDate?.toTimeString().substring(0, 5) ?? "",
    });
  }

  /**
   * gets the streetname of the energyconsult
   */
  private async setStreetName() {
    if (this.energyConsult) {
      const res = await this.energyConsultService.getStreetNameOfConsult(this.energyConsult!.id);
      const streetname = res.data.energyConsults.value[0].streetName;
      this.energyConsult.streetName = streetname;
    }
  }

  /**
   * gets the date of when the report was send
   */
  private async checkSendReportDate() {
    if (this.energyConsult) {
      const res = await this.getSendReportDate(this.energyConsult);
      if (res != null) {
        this.pdfSendDate = res;
      }
      this.sendDateAvailable = true;
    }
  }

  private async getSendReportDate(energyConsult: EnergyConsult) {
    const res = await this.coachEnergyConsultService.getSendReportDate(energyConsult);
    if (res) {
      const dateRes = new Date(res);
      const dateString = dateRes.toISOString().slice(0, 19).replace("T", " ").toString();
      return dateString;
    } else {
      return null;
    }
  }

  private async setSendReportDate() {
    if (this.energyConsult) {
      await this.coachEnergyConsultService.setSendReportDate(this.energyConsult);
    }
  }

  /**
   * Sends a mail
   */
  public async sendPDFMail() {
    if (this.energyConsult) {
      try {
        await this.energyConsultService.sendPDFToResident(this.energyConsult.id);
        this.openPDFSuccessDialog();
        await this.setSendReportDate();
        await this.checkSendReportDate();
      } catch (error) {
        this.snackbarService.open(this.translateService.instant("COMPONENTS.COACH_REQUEST.CLAIM.FAILURE_MESSAGE"));
      }
    }
  }

  /**
   * Opens a dialog if the pdf is successfull generated
   */
  private openPDFSuccessDialog() {
    this.dialog.open({
      template: this.pdfGenerateSuccess,
    });
  }

  /**
   * Saves the chosen date
   */
  public async saveDate() {
    if (this.energyConsult) {
      const date: Date = this.getGivenDate();
      this.energyConsult.state.name = RequestStates.DATE;
      const res = await this.coachEnergyConsultService.saveDate(this.energyConsult, date.toISOString());

      if (!res.errors) {
        this.isEditingDate = false;
        this.energyConsult.appointmentDate = date;
      }
      this.initEnergyConsult(this.energyConsult.id);
    }
  }

  /**
   * Claim a energyConsult
   */
  public async claimEnergyConsult() {
    if (this.energyConsult) {
      const coach = <Coach>this.application.session.user;
      this.energyConsult.state.name = RequestStates.PICKED;

      const res = await this.coachEnergyConsultService.AcceptEnergyConsult(this.energyConsult);
      if (!res.errors) {
        this.energyConsult.coach = coach;
      }
      this.initEnergyConsult(this.energyConsult.id);
    }
  }

  /**
   * Recovers a energyConsult
   */
  public async recoverEnergyConsult() {
    if (this.energyConsult) {
      this.energyConsult.coach = undefined;
      this.setEnergyConsultState(RequestStates.NEW, "");
    }
  }

  /**
   * Deletes a energyConsult
   */
  public async deleteEnergyConsult() {
    this.setEnergyConsultState(RequestStates.PENDING_DELETED, "");
  }

  /**
   * Sets the state of the energyConsult
   * @param state The state of the energyConsult
   * @param key The property of the energyConsult to update
   */
  private async setEnergyConsultState(state: RequestStates, key: string) {
    if (this.energyConsult) {
      this.energyConsult.state.name = state;
      await this.energyConsultService.save(this.energyConsult, key);
      await this.initEnergyConsult(this.energyConsult.id);
    }
  }

  /**
   * Saves the reject reason for the energyConsult
   */
  public async rejectEnergyConsult() {
    if (this.energyConsult && this.rejectReasonControl.valid) {
      this.energyConsult.state.name = RequestStates.PENDING_REJECT;
      this.energyConsult.rejectReason = this.rejectReasonControl.value;

      await this.coachEnergyConsultService.rejectEnergyConsult(this.energyConsult);

      this.initEnergyConsult(this.energyConsult.id);
      this.dialog.close();
    }
  }

  /**
   * Checks if it has an alert when the energyConsult is canceled, pending rejected or disapproved.
   */
  public get hasAlert(): boolean {
    return !!this.energyConsult && [RequestStates.PENDING_REJECT, RequestStates.CANCELED, RequestStates.DISAPPROVED].includes(this.energyConsult.state.name);
  }

  /**
   * Get the data from the energyConsult
   * Parse the date to locale
   */
  private async initEnergyConsult(id: number) {
    const energyConsult = await this.energyConsultService.loadById(id);
    if (!energyConsult.isActive) {
      energyConsult.resident = undefined;
    }
    this.energyConsult = energyConsult;
  }

  /**
   * Gets the selected date from the date form
   * @returns The selected date
   */
  private getGivenDate(): Date {
    const date: Date = this.formGroup.value.date;
    date.setHours(+this.formGroup.value.time.substr(0, 2), +this.formGroup.value.time.substr(3, 2));
    return date;
  }

  /**
   * Gets all coaches for the change coach autofill field
   * @returns Array with coaches
   */
  public async getCoaches() {
    const res = await this.coachService.getAllCoaches();
    this.coaches = res;
    return this.coaches;
  }

  /**
   * Assign a coach to a energyConsult (being a coordinator)
   * @param Coach the new coach
   */
  public async assignCoach(coach: Coach) {
    if (this.energyConsult) {
      await this.coachEnergyConsultService.AcceptEnergyConsult(this.energyConsult, coach);
      this.energyConsult.coach = coach;
      this.dialog.close();
    }
  }

  /**
   * First call gets all coaches and opens dialog, other calls just open dialog
   * @param TemplateRef the dialog template name
   */
  public async changeCoachClickHandler(template: TemplateRef<any>) {
    this.changeCoachControl.patchValue(this.energyConsult?.coach);
    if (!this.coaches) {
      await this.getCoaches().then(() => {
        this.dialog.show(template);
      });
    } else {
      this.dialog.show(template);
    }
  }

  /**
   * Concat first and last name
   * @returns String with fullname
   */
  public displayCoachName(coach: Coach) {
    return coach.firstName + " " + coach.lastName;
  }

  /**
   * sends the report as pdf to the coach for review
   */
  public async downloadReportAsPDF() {
    if (this.energyConsult) {
      try {
        const pdf = await this.reportService.getPdfFile(this.energyConsult.id);
        downloadFile(pdf, this.translateService.instant("COMPONENTS.COACH_REQUEST.REPORT"), "application/pdf");
      } catch (error) {
        this.snackbarService.open(this.translateService.instant("COMPONENTS.EVERYWHERE.ERROR.GENERIC"), "", 5000);
      }
    }
  }
}
