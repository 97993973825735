<div class="container">
  <div class="row">
    <div class="col">
      <h1>{{ "COMPONENTS.FAQ.TITLE" | translate }}</h1>
      <hr />
      <h2>{{ "COMPONENTS.FAQ.CATEGORY.SIGN_UP.TITLE" | translate }}</h2>
      <br />
      <h3>{{ "COMPONENTS.FAQ.CATEGORY.SIGN_UP.QUESTIONS.SIGN_UP_WITH_EMAIL.TITLE" | translate }}:</h3>

      <ol>
        <li>{{ "COMPONENTS.FAQ.CATEGORY.SIGN_UP.QUESTIONS.SIGN_UP_WITH_EMAIL.STEPS.FIRST" | translate }}</li>
        <li>{{ "COMPONENTS.FAQ.CATEGORY.SIGN_UP.QUESTIONS.SIGN_UP_WITH_EMAIL.STEPS.SECOND" | translate }}</li>
        <li>{{ "COMPONENTS.FAQ.CATEGORY.SIGN_UP.QUESTIONS.SIGN_UP_WITH_EMAIL.STEPS.THIRD" | translate }}</li>
        <li>{{ "COMPONENTS.FAQ.CATEGORY.SIGN_UP.QUESTIONS.SIGN_UP_WITH_EMAIL.STEPS.FOURTH" | translate }}</li>
        <li>{{ "COMPONENTS.FAQ.CATEGORY.SIGN_UP.QUESTIONS.SIGN_UP_WITH_EMAIL.STEPS.FIFTH" | translate }}</li>
      </ol>
      <h3>{{ "COMPONENTS.FAQ.CATEGORY.SIGN_UP.QUESTIONS.SIGN_UP_WITH_SOCIAL_ACCOUNT.TITLE" | translate }}:</h3>
      <ol>
        <li>{{ "COMPONENTS.FAQ.CATEGORY.SIGN_UP.QUESTIONS.SIGN_UP_WITH_SOCIAL_ACCOUNT.STEPS.FIRST" | translate }}</li>
        <li>{{ "COMPONENTS.FAQ.CATEGORY.SIGN_UP.QUESTIONS.SIGN_UP_WITH_SOCIAL_ACCOUNT.STEPS.SECOND" | translate }}</li>
        <li>{{ "COMPONENTS.FAQ.CATEGORY.SIGN_UP.QUESTIONS.SIGN_UP_WITH_SOCIAL_ACCOUNT.STEPS.THIRD" | translate }}</li>
      </ol>
      <hr />
      <h2>{{ "COMPONENTS.FAQ.CATEGORY.LOGIN.TITLE" | translate }}</h2>
      <br />
      <h3>{{ "COMPONENTS.FAQ.CATEGORY.LOGIN.QUESTIONS.LOGIN_WITH_EMAIL.TITLE" | translate }}:</h3>
      <ol>
        <li>{{ "COMPONENTS.FAQ.CATEGORY.LOGIN.QUESTIONS.LOGIN_WITH_EMAIL.STEPS.FIRST" | translate }}</li>
        <li>{{ "COMPONENTS.FAQ.CATEGORY.LOGIN.QUESTIONS.LOGIN_WITH_EMAIL.STEPS.SECOND" | translate }}</li>
        <li>{{ "COMPONENTS.FAQ.CATEGORY.LOGIN.QUESTIONS.LOGIN_WITH_EMAIL.STEPS.THIRD" | translate }}</li>
        <li>{{ "COMPONENTS.FAQ.CATEGORY.LOGIN.QUESTIONS.LOGIN_WITH_EMAIL.STEPS.FOURTH" | translate }}</li>
      </ol>
      <h3>{{ "COMPONENTS.FAQ.CATEGORY.LOGIN.QUESTIONS.LOGIN_WITH_SOCIAL_ACCOUNT.TITLE" | translate }}:</h3>
      <ol>
        <li>{{ "COMPONENTS.FAQ.CATEGORY.LOGIN.QUESTIONS.LOGIN_WITH_SOCIAL_ACCOUNT.STEPS.FIRST" | translate }}</li>
        <li>{{ "COMPONENTS.FAQ.CATEGORY.LOGIN.QUESTIONS.LOGIN_WITH_SOCIAL_ACCOUNT.STEPS.SECOND" | translate }}</li>
        <li>{{ "COMPONENTS.FAQ.CATEGORY.LOGIN.QUESTIONS.LOGIN_WITH_SOCIAL_ACCOUNT.STEPS.THIRD" | translate }}</li>
      </ol>
    </div>
  </div>
</div>
<p>{{ "COMPONENTS.FAQ.VERSION" | translate }}: {{ version }}</p>
