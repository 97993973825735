<div id="dashboardContainer">
  <div class="row">
    <div class="col-md" *ngIf="!applicationService.initialized else content">
      <mat-spinner></mat-spinner>
    </div>

    <ng-template #content>
      <router-outlet #children></router-outlet>
    </ng-template>
  </div>
</div>
