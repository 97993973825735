import { NgModule } from "@angular/core";
import { RouterModule, Route } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { BrowserUtils } from "@azure/msal-browser";
import { UsersComponent } from "./screens/content/coordinator/user/users.component";
import { AllQuestionnairesComponent } from "./screens/content/all-questionnaires/all-questionnaires.component";
import { NewQuestionnaireComponent } from "./screens/new-questionnaire/new-questionnaire.component";
import { ContentComponent } from "./screens/content/content";
import { CoachComponent } from "./screens/content/coach/coach.component";
import { CoordinatorComponent } from "./screens/content/coordinator/Coordinator.component";
import { UserAllComponent } from "./screens/content/coordinator/user/all/all.component";
import { ChooseRoleComponent } from "./screens/choose-role/choose-role.component";
import { CoachEnergyConsultComponent } from "./screens/content/coach/coach-energy-consult/coach-energy-consult.component";
import { NewCoachComponent } from "./screens/content/coordinator/new-coach/new-coach.component";
import { NewEnergyConsultComponent } from "./screens/guest/new-energy-consult/new-energy-consult.component";
import { DashboardComponent } from "./screens/content/shared/dashboard/dashboard";
import { Error_403Component } from "./screens/error/403/403";
import { Error_404Component } from "./screens/error/404/404";
import { ErrorComponent } from "./screens/error/error";
import { ProfileComponent } from "./screens/content/shared/profile/profile.component";
import { ReportComponent } from "./screens/report/report.component";
import { LoggedOutComponent } from "./screens/guest/logged-out/logged-out.component";
import { QuestionnaireComponent } from "./screens/content/all-questionnaires/questionnaire/questionnaire.component";
import { ViewReportComponent } from "./screens/view-report/view-report.component";
import { IsLoggedInGuard } from "./guards/isLoggedIn";
import { FAQComponent } from "./screens/faq/faq.component";
import { LoginFailedComponent } from "./screens/login-failed/login-failed.component";
import { RoleName } from "./classes/flow/session/Session";
import { HasRoleGaurd } from "./guards/hasRole";
import { IsActiveGuard } from "./guards/isActive";
import { ResidentComponent } from "./screens/content/resident/Resident.component";

export interface RouteData {
  allowedRoles: RoleName[];
  noAuthRedirectURL?: string;
}

interface AppRoute extends Route {
  data?: RouteData;
  children?: AppRoute[];
}

const routes: AppRoute[] = [
  {
    path: "",
    pathMatch: "full",
    redirectTo: "request",
  },
  {
    path: "content",
    component: ContentComponent,

    canActivate: [MsalGuard],
    children: [
      {
        path: "",
        pathMatch: "full",
        component: ContentComponent,
        canActivate: [IsActiveGuard],
      },
      {
        path: "coordinator",
        component: CoordinatorComponent,
        canActivate: [HasRoleGaurd, IsActiveGuard],
        data: { allowedRoles: ["coordinator"] },
        children: [
          {
            path: "",
            pathMatch: "full",
            redirectTo: "dashboard",
          },
          {
            path: "dashboard",
            component: DashboardComponent,
          },
          {
            path: "dashboard/:id",
            component: DashboardComponent,
          },
          {
            path: "new-coach",
            component: NewCoachComponent,
          },
          {
            path: "users",
            component: UsersComponent,
            children: [
              {
                path: "",
                pathMatch: "full",
                redirectTo: "all",
              },
              {
                path: "all",
                component: UserAllComponent,
              },
            ],
          },
          {
            path: "all-questionnaires",
            component: AllQuestionnairesComponent,
          },
          {
            path: "new-questionnaire",
            component: NewQuestionnaireComponent,
          },
          {
            path: "questionnaire/:id",
            component: QuestionnaireComponent,
          },
          {
            path: "request/:id",
            component: CoachEnergyConsultComponent,
          },
          {
            path: "**",
            redirectTo: "error/404",
          },
        ],
      },
      {
        path: "coach",
        component: CoachComponent,
        canActivate: [HasRoleGaurd, IsActiveGuard],
        data: { allowedRoles: ["coach"] },
        children: [
          {
            path: "",
            pathMatch: "full",
            redirectTo: "dashboard",
          },
          {
            path: "dashboard",
            component: DashboardComponent,
          },
          {
            path: "request/:id",
            component: CoachEnergyConsultComponent,
          },
          {
            path: "request/:id/report",
            component: ReportComponent,
          },
          {
            path: "**",
            redirectTo: "error/404",
          },
        ],
      },
      {
        path: "resident",
        component: ResidentComponent,
        canActivate: [HasRoleGaurd, IsActiveGuard],
        data: { allowedRoles: ["resident"] },
        children: [
          {
            path: "",
            pathMatch: "full",
            redirectTo: "dashboard",
          },
          {
            path: "dashboard",
            component: DashboardComponent,
          },
          {
            path: "report/:id",
            component: ViewReportComponent,
          },
          {
            path: "**",
            redirectTo: "error/404",
          },
        ],
      },
      {
        path: "profile",
        component: ProfileComponent,
      },
      {
        path: "profile/:id",
        component: ProfileComponent,
        canActivate: [IsActiveGuard],
      },
    ],
  },
  {
    path: "error",
    component: ErrorComponent,
    children: [
      {
        path: "404",
        component: Error_404Component,
      },
      {
        path: "403",
        component: Error_403Component,
      },
    ],
  },
  {
    path: "login",
    component: NewEnergyConsultComponent,
  },
  {
    path: "login-failed",
    component: LoginFailedComponent,
  },
  {
    path: "faq",
    component: FAQComponent,
  },
  {
    path: "loggedout",
    canActivate: [IsLoggedInGuard],
    component: LoggedOutComponent,
  },
  {
    path: "role-init",
    pathMatch: "full",
    component: ChooseRoleComponent,
  },
  {
    path: "request",
    canActivate: [HasRoleGaurd],
    data: {
      allowedRoles: ["resident", "guest", "user"],
      noAuthRedirectURL: "/content",
    },
    component: NewEnergyConsultComponent,
  },
  { path: "**", redirectTo: "error/404" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? "enabled" : "disabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
