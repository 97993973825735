import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Questionnaire } from "../../classes/flow/Questionnaire/Questionnaire";
import { QuestionnaireFormGroup } from "../../classes/flow/Questionnaire/QuestionnaireFormGroup";
import { DialogService } from "../../services/dialog.service";
import { GraphQLService } from "../../services/graphql.service";
import { QuestionService } from "../../services/question.service";
import { ReportService } from "../../services/report.service";
import { EnergyConsultService } from "../../services/energy-consult.service";
import { SnackbarService } from "../../services/snackbar.service";
import { Question, Logic } from "../../classes/flow/Questionnaire/Question";
import { TranslateService } from "@ngx-translate/core";
import { questionAnswerValidator } from "../../validators/questionAnswer";
import { RequestStates } from "../../classes/flow/request/RequestStates";
import { EnergyConsult } from "../../classes/flow/request/EnergyConsult";
import { downloadFile } from "../../helpers/downloadFile";

interface Listener {
  parent: Question;
  child: Question;
  listening: boolean;
}

@Component({
  selector: "app-report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.less"],
})
export class ReportComponent implements OnInit {
  public questionnaireFormGroups: Array<QuestionnaireFormGroup> = [];
  public questionnaires: Array<Questionnaire> = [];
  public activeQuestionnaires: FormControl = new FormControl();
  public energyConsult?: EnergyConsult;

  @ViewChild("confirmationDialog")
  public confirmationDialog!: TemplateRef<unknown>;

  constructor(
    protected readonly router: Router,
    public readonly route: ActivatedRoute,
    public readonly graphqlService: GraphQLService,
    public readonly snackService: SnackbarService,
    public readonly dialogService: DialogService,
    protected readonly reportService: ReportService,
    protected readonly energyConsultService: EnergyConsultService,
    private readonly questionService: QuestionService,
    protected readonly translateService: TranslateService
  ) {}

  public async ngOnInit() {
    await this.retrieveRequest();
    await this.retrieveReport();
  }

  private async retrieveRequest() {
    this.energyConsult = await this.energyConsultService.loadById(this.retrieveRequestId());
  }

  /**
   * Retrieves the id of the energyConsult and the report
   */
  private retrieveRequestId(): number {
    const energyConsultId = this.route.snapshot.paramMap.get("id")!;
    return energyConsultId as unknown as number;
  }

  /**
   * initial setup for the questionnaire
   */
  protected async retrieveReport() {
    if (this.energyConsult) {
      try {
        if (this.energyConsult.state.name === "Date") {
          this.questionnaires = await this.reportService.getAllQuestionsAndAnswers(this.energyConsult.id);
          this.retrieveQuestionnaires();
        } else {
          this.snackService.open(this.translateService.instant("FORMS.REPORT.ERROR.NOT_PICKED"));
          this.router.navigate(["/content/coach/energyConsult/", this.energyConsult.id]);
        }
      } catch (error) {
        this.snackService.open(this.translateService.instant("COMPONENTS.EVERYWHERE.ERROR.RETRIEVE_DATA"));
        this.router.navigate(["/content"]);
      }
    }
  }

  /**
   * Retrieves the questionnaires
   */
  protected retrieveQuestionnaires() {
    this.questionnaires.forEach((questionnaire) => {
      this.markupQuestions(questionnaire.questions!, questionnaire.id);
    });
    this.addParentQuestionLogicListener();
    this.activeQuestionnaires.patchValue(this.questionnaires);
  }

  /**
   * Navigates back to energyConsult
   */
  public navigateBackToRequest() {
    let url = window.location.pathname;
    let to = url.lastIndexOf("/");
    to = to == -1 ? url.length : to + 1;
    url = url.substring(0, to);
    this.router.navigate([url]);
  }

  /**
   * Sets the child questions to disabled if parent question answer is not good for the child logic. Disabled questions are not shown in the html.
   */
  protected addParentQuestionLogicListener() {
    const listeners: Listener[] = [];
    for (const questionnaire of this.questionnaires) {
      this.createQuestionHierarchy(questionnaire, listeners);
      this.handleListeners(questionnaire, listeners);
    }
  }

  /**
   * Handles listeners that includes the parent questions with their child questions
   * @param questionnaire The questionnaire that belongs to the listeners
   * @param listeners The listeners that includes the parent questions with their child questions
   */
  private handleListeners(questionnaire: Questionnaire, listeners: Listener[]) {
    listeners.forEach((e) => {
      if (e.child.logic?.compareAnswer && e.child.logic.operatorType) {
        if (!e.parent.answer?.answer || !this.checkCompare(e.parent.answer.answer, e.child.logic)) {
          this.disableQuestion(questionnaire, e.child);
        }
      } else if (e.child.logic?.compareOptionId) {
        if (!e.parent.answer?.answer || e.parent.answer.answer !== e.child.logic?.compareOptionId.toString()) {
          this.disableQuestion(questionnaire, e.child);
        }
      }

      if (!e.listening) {
        this.handleValueChanges(questionnaire, e);
        e.listening = true;
      }
    });
  }

  /**
   * Handles values changes when a user select another answer
   * @param questionnaire The questionnaire that belongs to the listeners
   * @param listener The listener with the parent question and his child questions
   */
  private handleValueChanges(questionnaire: Questionnaire, listener: Listener) {
    this.returnFormGroup(questionnaire.id!)?.controls[listener.parent.id!].valueChanges.subscribe((res) => {
      const logic = listener.child.logic;
      if (logic?.compareAnswer && logic.operatorType) {
        if (this.checkCompare(res, logic)) {
          this.returnFormGroup(questionnaire.id!)?.controls[listener.child.id!].enable();
        } else {
          this.disableQuestion(questionnaire, listener.child);
        }
      } else if (logic?.compareOptionId) {
        if (logic.compareOptionId === res) {
          this.returnFormGroup(questionnaire.id!)?.controls[listener.child.id!].enable();
        } else {
          this.disableQuestion(questionnaire, listener.child);
        }
      }
    });
  }

  /**
   * Creates the hierarchy between all questions of a questionnaire
   * @param questionnaire The questionnaire with the questions
   * @param listeners The list for saving the listeners
   */
  private createQuestionHierarchy(questionnaire: Questionnaire, listeners: Listener[]) {
    for (const question of questionnaire.questions || []) {
      if (question.logic) {
        questionnaire.questions?.map((e) => {
          if (e.id === question.logic?.parentQuestionId) {
            listeners.push({ parent: e, child: question, listening: false });
            e.followUpQuestions.push(question);
          }
        });
      }
    }
  }

  /**
   * Disables the control of the question, as well as his follow up questions
   * @param questionnaire The questionnaire that includes the given question
   * @param question The question to disable
   */
  private disableQuestion(questionnaire: Questionnaire, question: Question) {
    this.returnFormGroup(questionnaire.id!)?.controls[question.id!]?.disable();
    if (question.followUpQuestions.length) {
      for (const cQ of question.followUpQuestions) {
        this.disableQuestion(questionnaire, cQ);
      }
    }
  }

  /**
   * Checks the compare answer with the answer
   * @param res The first answer
   * @param logic The logic of the second answer
   * @returns The result of the comparison
   */
  protected checkCompare(res: string, logic: Logic): boolean {
    if (!logic.compareAnswer) return false;
    switch (logic.operatorType?.operation) {
      case "===":
        return res.toLowerCase() === logic.compareAnswer.toLowerCase();
      case "!=":
        return res.toLowerCase() != logic.compareAnswer.toLowerCase();
      case ">":
        return parseInt(res) > parseInt(logic.compareAnswer);
      case "<":
        return parseInt(res) > parseInt(logic.compareAnswer);
    }
    return false;
  }

  /**
   * initial setup of the questions
   * @param questions
   * @param questionListId
   * @returns An array with questions
   */
  protected markupQuestions(questions: Array<Question>, questionListId = 0): Question[] {
    const questionsArray: Array<Question> = [];
    const formGroup: FormGroup = new FormGroup({});

    questions.forEach((question: Question) => {
      const validators = [questionAnswerValidator(question.questionType, question.validation)];
      if (question.required) validators.push(Validators.required);
      formGroup.addControl(question.id!.toString(), new FormControl(question.answer?.answer ?? "", validators));
      formGroup.updateValueAndValidity();

      questionsArray.push(question);
    });

    this.questionnaireFormGroups.push({ form: formGroup, formId: questionListId });

    return questionsArray;
  }

  /**
   * Gets and returns a FormGroup by id.
   * @param id If the question is new: FormGroup Id. If the question is from the DB: Question id.
   * @returns FormGroup
   */
  public returnFormGroup(id: number): FormGroup | undefined {
    return this.questionnaireFormGroups.find((questionnaireFormGroup: QuestionnaireFormGroup) => {
      return questionnaireFormGroup.formId === id;
    })?.form;
  }

  /**
   * Opens confirmation dialog
   */
  public openConfirmation() {
    this.dialogService.open({
      template: this.confirmationDialog,
    });
  }

  /**
   * sets the energyConsult to done when all questions are answered.
   */
  public async saveForm() {
    if (this.energyConsult && this.validateAllActiveQuestionnaires()) {
      try {
        this.energyConsult.state.name = RequestStates.FILLED_IN;
        await this.energyConsultService.setState(this.energyConsult);
        await this.deleteAllUnusedAnswers();
        this.snackService.open(this.translateService.instant("FORMS.REPORT.SUCCESS.FILLED_IN"), "", 5000);
        this.router.navigate(["/content/coach/energyConsult/", this.energyConsult.id]);
      } catch (error) {
        this.snackService.open(this.translateService.instant("COMPONENTS.EVERYWHERE.ERROR.GENERIC"), "", 5000);
      }
    } else {
      this.snackService.open(this.translateService.instant("FORMS.REPORT.ERROR.WRONG_ANSWER"), "", 6000);
    }
  }

  /**
   * sends the report as pdf to the coach for review
   */
  public async downloadReportAsPDF() {
    if (this.energyConsult) {
      try {
        const pdf = await this.reportService.getPdfFile(this.energyConsult.id);
        downloadFile(pdf, this.translateService.instant("COMPONENTS.COACH_REQUEST.TEMPORARY_PDF"), "application/pdf");
      } catch (error) {
        this.snackService.open(this.translateService.instant("COMPONENTS.EVERYWHERE.ERROR.GENERIC"), "", 5000);
      }
    }
  }

  /**
   * Saves a question answer
   * @param question The question
   * @param questionnaireId the id for a questionnaire
   */
  public async saveQuestion(question: Question, questionnaireId: number) {
    if (this.energyConsult) {
      const formGroup = this.returnFormGroup(questionnaireId);
      if (formGroup?.controls[question.id!].invalid) {
        this.snackService.open(this.translateService.instant("FORMS.REPORT.ERROR.SAVE_ANSWER"), "", 3000);
        return;
      }
      try {
        const answer: string = "" + formGroup!.value[question.id!];
        await this.insertAnswer(question, answer.replace("\n", "\\n"));
        this.snackService.open(this.translateService.instant("FORMS.REPORT.SUCCESS.SAVE_ANSWER"), "", 2000);
      } catch (error) {
        this.snackService.open(this.translateService.instant("COMPONENTS.EVERYWHERE.ERROR.SAVE_DATA"));
      }
    }
  }

  /**
   * Inserts an answer on the right way
   * @param question The question that has been answered
   * @param formGroup The formGroup of the questionnaire
   */
  private async insertAnswer(question: Question, answer: string) {
    if (this.energyConsult) {
      if (question.answer) {
        question.answer.answer = answer;
        await this.questionService.updateAnswerForQuestion(question.answer);
      } else {
        question.answer = {
          questionId: question.id!,
          answer: answer,
          energyConsultId: +this.energyConsult.id,
        };
        await this.questionService.insertAnswerForQuestion(question.answer);
      }
    }
  }

  /**
   * Deletes all answers of the disabled questionnaires
   */
  private async deleteAllUnusedAnswers() {
    if (this.energyConsult) {
      const idsOfAllUnusedAnswers: number[] = this.getAllDisabledQuestionsWithAnswer().concat(this.getAllInactiveQuestionsWithAnswer());
      this.questionService.deleteAllUnusedAnswers(idsOfAllUnusedAnswers, +this.energyConsult.id);
    }
  }

  /**
   * Gets all disabled questions with an answer
   * @returns A list with ids of all disabled questions
   */
  private getAllDisabledQuestionsWithAnswer(): number[] {
    const formGroupsOfActiveQuestionnaires: FormGroup[] = this.activeQuestionnaires.value.map((questionnaire: any) => {
      return this.returnFormGroup(questionnaire.id);
    });
    return this.getIdsOfAllDisabledQuestionsWithAnswer(formGroupsOfActiveQuestionnaires);
  }

  /**
   * Gets the ids of all disabled questions with an answer
   * @param formGroups All formgroups for questionnaires
   * @returns A list with ids of all disabled questions
   */
  private getIdsOfAllDisabledQuestionsWithAnswer(formGroups: FormGroup[]): number[] {
    const idsOfAllDisabledQuestions: number[] = [];
    formGroups.forEach((formGroup) => {
      for (const [questionId, formControl] of Object.entries(formGroup.controls)) {
        if (formControl.disabled) {
          idsOfAllDisabledQuestions.push(+questionId);
        }
      }
    });
    return idsOfAllDisabledQuestions;
  }

  /**
   * Gets all inactive questions with an answer
   * @returns A list with ids of all inactive questions
   */
  private getAllInactiveQuestionsWithAnswer(): number[] {
    const inactiveQuestionnaires = this.questionnaires.filter((questionnaire) => !this.activeQuestionnaires.value.includes(questionnaire));
    let idsOfAllInactiveQuestions: number[] = [];
    inactiveQuestionnaires.forEach((questionnaire) => {
      idsOfAllInactiveQuestions = idsOfAllInactiveQuestions.concat(this.getIdsOfAllInactiveQuestionsWithAnswer(questionnaire.questions ?? []));
    });
    return idsOfAllInactiveQuestions;
  }

  /**
   * Gets the ids of all inactive questions with an answer
   * @param questions All active and inactive questions
   * @returns A list with ids of all inactive questions
   */
  private getIdsOfAllInactiveQuestionsWithAnswer(questions: Question[]): number[] {
    let idsOfAllInactiveQuestions: number[] = [];
    questions.forEach((question) => {
      if (question.answer) {
        idsOfAllInactiveQuestions.push(question.id!);
      }
      idsOfAllInactiveQuestions = idsOfAllInactiveQuestions.concat(this.getIdsOfAllInactiveQuestionsWithAnswer(question.followUpQuestions));
    });
    return idsOfAllInactiveQuestions;
  }

  public toQuestionnaire(questionnaire: Questionnaire): Questionnaire {
    return questionnaire as Questionnaire;
  }

  /**
   * Validates all active questionnaires
   * @returns If all questionnaires passing the validation it will return true, false otherwise
   */
  public validateAllActiveQuestionnaires(): boolean {
    const activeQuestionnaires: Questionnaire[] = this.activeQuestionnaires.value;
    return !this.questionnaireFormGroups.some((fg) => activeQuestionnaires.find((questionnaire) => questionnaire.id == fg.formId) && fg.form.invalid);
  }

  /**
   * saves value from param event
   * @param event holds the value from the time range input hh:mm-hh:mm
   * @param question question to know where to save the value in the db
   */
  public async saveTimeRangeInput(event: string, question: Question, formgroupId: number) {
    if (this.energyConsult) {
      try {
        const formGroup = this.returnFormGroup(formgroupId);
        formGroup!.controls[question.id!].setValue(event);
        await this.insertAnswer(question, event);
        this.snackService.open(this.translateService.instant("FORMS.REPORT.SUCCESS.SAVE_ANSWER"), "", 2000);
      } catch (error) {
        this.snackService.open(this.translateService.instant("COMPONENTS.EVERYWHERE.ERROR.SAVE_DATA"));
      }
    }
  }

  /**
   * Compares two options
   * @param firstOption The first option
   * @param secondOption The second option
   * @returns True if the entities are equal, false otherwise
   */
  public compareOptions(firstOption: string, secondOption: string) {
    return firstOption == secondOption;
  }
}
