<div class="container">
  <div class="row">
    <div id="actionsContainer" class="col-md d-flex justify-content-between">
      <span>
        <button mat-flat-button (click)="navigateBackToRequest()">
          <mat-icon>arrow_back_ios_new</mat-icon>
          <span>{{ "PAGES.BACK" | translate }}</span>
        </button>
      </span>

      <mat-form-field *ngIf="questionnaires.length" appearance="outline" class="">
        <mat-label>Vragenlijsten</mat-label>
        <mat-select [formControl]="activeQuestionnaires" multiple>
          <mat-option *ngFor="let questionnaire of questionnaires" [value]="questionnaire" [disabled]="questionnaire.questionnaireType.name !== 'regular'">
            {{ questionnaire.title }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="questionnaires.length; else noQuestionnaires">
    <div style="border-left: var(--darker-outline-color) 2px solid" class="row mb-5" *ngFor="let questionnaire of activeQuestionnaires.value">
      <div *ngIf="questionnaire.questionnaireType.name !== 'regular'" class="col-md-12">
        <h2>{{ "FORMS.REPORT.QUESTIONNAIRE." + questionnaire.questionnaireType.name.toUpperCase() | translate }}</h2>
      </div>
      <div *ngIf="questionnaire.questionnaireType.name === 'regular'" class="col-md-12">
        <h2>{{ "FORMS.REPORT.QUESTIONNAIRE.TITLE" | translate : { title: questionnaire.title } }}</h2>
        <h4>{{ "FORMS.REPORT.QUESTIONNAIRE.DESCRIPTION" | translate : { description: questionnaire.description } }}</h4>
        <p>{{ "FORMS.REPORT.QUESTIONNAIRE.REMARK" | translate }}</p>
      </div>
      <div class="mt-3">
        <ng-container [ngTemplateOutlet]="formTemplate" [ngTemplateOutletContext]="{ data: questionnaire }"> </ng-container>
      </div>
    </div>
    <button mat-raised-button (click)="navigateBackToRequest()">{{ "COMPONENTS.DIALOG.BACK" | translate }}</button>
    <button mat-raised-button (click)="downloadReportAsPDF()">{{ "COMPONENTS.DIALOG.DOWNLOAD_PDF" | translate }} <mat-icon>download</mat-icon></button>
    <button mat-raised-button (click)="openConfirmation()">{{ "COMPONENTS.DIALOG.COMPLETE" | translate }}</button>
  </div>

  <ng-template #noQuestionnaires>
    <p>{{ "FORMS.REPORT.ERROR.NO_QUESTIONNAIRES" | translate }}</p>
  </ng-template>
</div>

<ng-template #formTemplate let-data="data">
  <ng-container *ngIf="toQuestionnaire(data); let data">
    <form [formGroup]="returnFormGroup(data.id!)!">
      <div class="row" *ngFor="let question of data.questions">
        <div class="col-md">
          <div *ngIf="question.logic" [hidden]="returnFormGroup(data.id!)?.controls?.[question.id!]?.disabled">
            <h5>{{ question.name }}</h5>
            <mat-form-field *ngIf="question.questionType.prefix === 'sel'" appearance="outline">
              <mat-label>{{ question.placeholder }}</mat-label>
              <mat-select (selectionChange)="saveQuestion(question, data.id!)" [formControlName]="question.id!">
                <mat-option *ngFor="let selOption of question.options" [value]="selOption.id">
                  {{ selOption.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="question.questionType.prefix === 'txt'" class="" appearance="outline">
              <mat-label>{{ question.placeholder }}</mat-label>
              <input matInput (change)="saveQuestion(question, data.id!)" [formControlName]="question.id!" />
              <app-error [control]="returnFormGroup(data.id!)?.controls?.[question.id!]"></app-error>
            </mat-form-field>

            <mat-form-field *ngIf="question.questionType.prefix === 'dat'" class="" appearance="outline">
              <mat-label>{{ question.placeholder }}</mat-label>
              <input matInput (change)="saveQuestion(question, data.id!)" [formControlName]="question.id!" type="date" />
              <app-error [control]="returnFormGroup(data.id!)?.controls?.[question.id!]"></app-error>
            </mat-form-field>

            <div *ngIf="question.questionType.prefix === 'trp'">
              <app-time-range-picker (eventEmitter)="saveTimeRangeInput($event, question, data.id!)" [savedTime]="question.answer?.answer"></app-time-range-picker>
            </div>

            <mat-form-field *ngIf="question.questionType.prefix === 'txa'" class="" appearance="outline" style="width: 100%">
              <mat-label>{{ question.placeholder }}</mat-label>
              <textarea matInput (change)="saveQuestion(question, data.id!)" [formControlName]="question.id!"></textarea>
              <app-error [control]="returnFormGroup(data.id!)?.controls?.[question.id!]"></app-error>
            </mat-form-field>
          </div>

          <div *ngIf="!question.logic">
            <h5>{{ question.name }}</h5>
            <p class="text-break">{{ question.description }}</p>
            <mat-form-field *ngIf="question.questionType.prefix === 'sel'" appearance="outline">
              <mat-label>{{ question.placeholder }}</mat-label>
              <mat-select [compareWith]="compareOptions" (selectionChange)="saveQuestion(question, data.id!)" [formControlName]="question.id!">
                <mat-option *ngFor="let selOption of question.options" [value]="selOption.id">
                  {{ selOption.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="question.questionType.prefix === 'txt'" appearance="outline">
              <mat-label>{{ question.placeholder }}</mat-label>
              <input matInput (change)="saveQuestion(question, data.id!)" [formControlName]="question.id!" />
              <app-error [control]="returnFormGroup(data.id!)?.controls?.[question.id!]"></app-error>
            </mat-form-field>

            <mat-form-field *ngIf="question.questionType.prefix === 'dat'" appearance="outline">
              <mat-label>{{ question.placeholder }}</mat-label>
              <input matInput type="date" (change)="saveQuestion(question, data.id!)" [formControlName]="question.id!" />
              <app-error [control]="returnFormGroup(data.id!)?.controls?.[question.id!]"></app-error>
            </mat-form-field>

            <div *ngIf="question.questionType.prefix === 'trp'">
              <app-time-range-picker (eventEmitter)="saveTimeRangeInput($event, question, data.id!)" [savedTime]="question.answer?.answer"></app-time-range-picker>
            </div>

            <mat-form-field *ngIf="question.questionType.prefix === 'txa'" class="" appearance="outline" style="width: 100%">
              <mat-label>{{ question.placeholder }}</mat-label>
              <textarea matInput (change)="saveQuestion(question, data.id!)" [formControlName]="question.id!"></textarea>
              <app-error [control]="returnFormGroup(data.id!)?.controls?.[question.id!]"></app-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
</ng-template>

<ng-template #confirmationDialog>
  <div class="row">
    <div col-md-4>
      <h3>{{ "FORMS.REPORT.SUCCESS.CONFIRMATION" | translate }}</h3>
      <button mat-raised-button (click)="dialogService.close()">{{ "COMPONENTS.DIALOG.CANCEL" | translate }}</button>
      <button mat-raised-button (click)="saveForm(); dialogService.close()">{{ "COMPONENTS.DIALOG.COMPLETE" | translate }}</button>
    </div>
  </div>
</ng-template>
