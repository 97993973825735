<div class="container" *ngIf="this.applicationService.initialized; else loading">
  <div class="row">
    <div class="col-md-12">
      <h2>{{ "FORMS.NEW_ENERGY_CONSULT.TITLE" | translate }}</h2>
    </div>
  </div>
</div>
<div class="container">
  <div class="row">
    <div class="col-md-12">
      <ul>
        <li>{{ "FORMS.NEW_ENERGY_CONSULT.DESCRIPTION.STEPS.FIRST" | translate }}</li>
        <li>{{ "FORMS.NEW_ENERGY_CONSULT.DESCRIPTION.STEPS.SECOND" | translate }}</li>
        <li>{{ "FORMS.NEW_ENERGY_CONSULT.DESCRIPTION.STEPS.THIRD" | translate }}</li>
      </ul>
      <br />
      <form [formGroup]="formGroup">
        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline">
              <mat-label>{{ "FORMS.NEW_ENERGY_CONSULT.FIELDS.EMAIL" | translate }}</mat-label>
              <input matInput formControlName="email" placeholder="jan@gmail.com" type="email" />
              <app-error [control]="formGroup.controls['email']"></app-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline">
              <mat-label>{{ "FORMS.NEW_ENERGY_CONSULT.FIELDS.FIRST_NAME" | translate }}</mat-label>
              <input matInput formControlName="firstName" placeholder="Jan" type="text" />
              <app-error [control]="formGroup.controls['firstName']"></app-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline">
              <mat-label>{{ "FORMS.NEW_ENERGY_CONSULT.FIELDS.LAST_NAME" | translate }}</mat-label>
              <input matInput formControlName="lastName" placeholder="Jansen" type="text" />
              <app-error [control]="formGroup.controls['lastName']"></app-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline">
              <mat-label>{{ "FORMS.NEW_ENERGY_CONSULT.FIELDS.PHONE_NUMBER" | translate }}</mat-label>
              <input matInput formControlName="phoneNumber" type="tel" />
              <app-error [control]="formGroup.controls['phoneNumber']"></app-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <mat-form-field appearance="outline">
              <mat-label>{{ "FORMS.NEW_ENERGY_CONSULT.FIELDS.POSTAL_CODE" | translate }}</mat-label>
              <input matInput formControlName="postalCode" placeholder="1234AB" type="text" />
              <app-error [control]="formGroup.controls['postalCode']"></app-error>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field appearance="outline">
              <mat-label>{{ "FORMS.NEW_ENERGY_CONSULT.FIELDS.HOUSE_NUMBER" | translate }}</mat-label>
              <input matInput formControlName="houseNumber" placeholder="12" type="text" />
              <app-error [control]="formGroup.controls['houseNumber']"></app-error>
            </mat-form-field>
          </div>
          <div class="col-4">
            <mat-form-field appearance="outline">
              <mat-label>{{ "FORMS.NEW_ENERGY_CONSULT.FIELDS.HOUSE_NUMBER_SUFFIX" | translate }}</mat-label>
              <input matInput formControlName="houseNumberSuffix" placeholder="A" type="text" />
              <app-error [control]="formGroup.controls['houseNumberSuffix']"></app-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row" *ngIf="false">
          <div class="col-12">
            <mat-form-field appearance="outline">
              <mat-label>{{ "FORMS.NEW_ENERGY_CONSULT.FIELDS.SPECIALIZATION" | translate }}</mat-label>
              <mat-select formControlName="specialties">
                <mat-option *ngFor="let specialty of allSpecialties" [value]="specialty">{{ specialty.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline">
              <mat-label>{{ "FORMS.NEW_ENERGY_CONSULT.FIELDS.EXTRA_MESSAGE.LABEL" | translate }}</mat-label>
              <textarea
                rows="3"
                [placeholder]="'FORMS.NEW_ENERGY_CONSULT.FIELDS.EXTRA_MESSAGE.PLACEHOLDER' | translate"
                matInput
                formControlName="extraMessage"
                type="text"
              ></textarea>
              <app-error [control]="formGroup.controls['extraMessage']"></app-error>
            </mat-form-field>
          </div>
        </div>
      </form>
      <button mat-raised-button [disabled]="!formGroup.valid" (click)="saveNewEnergyConsultForm()">{{ "FORMS.NEW_ENERGY_CONSULT.SAVE" | translate }}</button>
    </div>
  </div>
</div>

<ng-template #popup>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md">
        <h3 class="title">{{ "FORMS.NEW_ENERGY_CONSULT.SUCCESS_MESSAGE" | translate }} 🎉</h3>
        <p>{{ "FORMS.NEW_ENERGY_CONSULT.SUCCESS_MESSAGE_DESCRIPTION" | translate }}</p>
        <button mat-raised-button (click)="userService.login()">{{ "FORMS.NEW_ENERGY_CONSULT.SUCCESS_MESSAGE_LOGIN" | translate }}</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <mat-spinner></mat-spinner>
</ng-template>
