import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class SnackbarService {
  public constructor(private snackBar: MatSnackBar, private readonly translateService: TranslateService) {}

  /**
   * Opens a snackbar
   * @param message
   * @param action
   */
  public open(message: string, action = "", duration = 5000) {
    this.snackBar.open(message, action, {
      duration: duration,
    });
  }

  public error() {
    this.open(this.translateService.instant("COMPONENTS.EVERYWHERE.ERROR.GENERIC"), "Fout");
  }
}