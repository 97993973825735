import { Changes } from "../../base/Changes";
import { Request } from "../../request/Request";
import { Role } from "../Session";
import { User, UserStatus } from "./User";

export class Resident extends User {
  public id: number;
  public phoneNumber?: string;
  public postalCode?: string;
  public houseNumber?: number;
  public houseNumberSuffix?: string;
  public requests: Request[] = [];

  public constructor({
    userId,
    roles = [],
    firstName,
    lastName,
    phoneNumber,
    postalCode,
    houseNumber,
    houseNumberSuffix,
    email,
    id,
    accessStartingDate,
    accessEndDate,
    changes
  }: {
    userId?: number;
    roles?: Role[];
    firstName: string;
    lastName: string;
    phoneNumber?: string;
    postalCode?: string;
    houseNumber?: number;
    houseNumberSuffix?: string;
    email: string;
    status?: UserStatus;
    id: number;
    accessStartingDate?: Date;
    accessEndDate?: Date;
    changes?: Changes
  }) {
    super({ id: userId, firstName: firstName, lastName: lastName, email: email, roles: roles, accessStartingDate: accessStartingDate, accessEndDate: accessEndDate, changes: changes });
    this.phoneNumber = phoneNumber;
    this.postalCode = postalCode;
    this.houseNumber = houseNumber;
    this.houseNumberSuffix = houseNumberSuffix;
    this.id = id;
  }

  public incomplete(): boolean {
    return super.incomplete() || (this.active && !(this.phoneNumber && this.postalCode && this.houseNumber));
  }
}
