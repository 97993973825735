import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { ApplicationService } from "../../../services/application.service";
import { DialogService } from "../../../services/dialog.service";
import { SnackbarService } from "../../../services/snackbar.service";
import { QuestionnaireService } from "../../../services/questionnaire.service";
import { Questionnaire } from "../../../classes/flow/Questionnaire/Questionnaire";
import { Specialty } from "../../../classes/flow/Questionnaire/Specialty";
import { TranslateService } from "@ngx-translate/core";
import { downloadFile } from "../../../helpers/downloadFile";

@Component({
  selector: "app-all-questionnaires",
  templateUrl: "./all-questionnaires.component.html",
  styleUrls: ["./all-questionnaires.component.less"],
})
export class AllQuestionnairesComponent implements OnInit {
  @ViewChild("deleteDialog")
  public deleteDialog!: TemplateRef<unknown>;

  public questionnaires: Questionnaire[] = [];

  public constructor(
    private readonly questionnaireService: QuestionnaireService,
    private readonly translateService: TranslateService,
    public readonly dialogService: DialogService,
    private readonly applicationService: ApplicationService,
    private readonly snackService: SnackbarService
  ) {}

  public ngOnInit() {
    this.applicationService.updateTitle(this.translateService.instant("COMPONENTS.ALL_QUESTIONNAIRES.TITLE"));
    this.initQuestionnaires();
  }

  /**
   * Formats the given specialties in one string, listing all their names
   * @param specialties The collection of specialties to join together
   * @returns A string containing the names of the given specialties
   */
  public formatSpecialties(specialties: Specialty[]): string {
    if (!specialties.length) {
      return this.translateService.instant("COMPONENTS.ALL_QUESTIONNAIRES.NO_SPECIALTY");
    }
    return specialties.map((s) => s.name).join(",\n");
  }

  /**
   * Opens a dialog for deletion
   * @param questionnaire The questionnaire to delete
   */
  public openDeleteDialog: (questionnaire: object) => void = (questionnaire) => {
    this.dialogService.open({
      template: this.deleteDialog,
      data: (questionnaire as Questionnaire),
    });
  };

  /**
   * Opens a pdf file of the questionnaire
   * @param questionnaire The questionnaire for creating a pdf file
   */
  public openPdfFile: (questionnaire: object) => void = async (questionnaire) => {
    const pdf = await this.questionnaireService.getPdfFile((questionnaire as Questionnaire).id!);
    downloadFile(pdf, "Checklist", "application/pdf");
  };

  /**
   * Tries to delete the specified questionnaire
   * @param id The id of the questionnaire to delete
   */
  public async deleteQuestionnaire(questionnaire: Questionnaire) {
    try {
      await this.questionnaireService.deleteQuestionnaire(questionnaire);
      this.questionnaires = this.questionnaires.filter((c) => c.id! !== questionnaire.id);
      this.snackService.open(this.translateService.instant("COMPONENTS.ALL_QUESTIONNAIRES.DELETE_QUESTIONNAIRE.SUCCESS_MESSAGE"));
    } catch (error) {
      this.snackService.open(this.translateService.instant("COMPONENTS.ALL_QUESTIONNAIRES.DELETE_QUESTIONNAIRE.FAILURE_MESSAGE"));
    }
  }

  /**
   * Try to fetch all questionnaires
   */
  private async initQuestionnaires() {
    try {
      this.questionnaires = await this.questionnaireService.getQuestionnaires();
    } catch (error) {
      this.snackService.open(this.translateService.instant("COMPONENTS.EVERYWHERE.ERROR.RETRIEVE_DATA"));
    }
  }
}
