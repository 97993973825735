import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  /**
   * Stores a value under the given key
   * @param key The key
   * @param value The value to store
   */  
  public store<T>(key: string, value: T) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * Fetches an object from the storage by key
   * @param key The key to fetch the value for
   * @returns The value, or undefined if there was no value found
   */
  public fetch<T>(key: string): T | null {
    return this.has(key) ? JSON.parse(localStorage.getItem(key)!) : null;
  }

  /**
   * Checks whether a value is present for the given key
   * @param key The key to check for
   * @returns True if a value exists for the key, false otherwise
   */
  public has(key: string): boolean {
    return !!localStorage.getItem(key);
  }

  /**
   * Removes all items from storage
   */
  public clear() {
    localStorage.clear();
  }
}