<div class="request">
  <div class="row">
    <div class="iconContainer">
      <span class="icon">
        <mat-icon>checklist_rtl</mat-icon>
      </span>
    </div>

    <div class="col data">
      <span class="info">{{ "COMPONENTS.TILE.STATUS" | translate }}</span>
      <span class="status mb-1">
        {{ "COMPONENTS.TILE.STATUSES." + energyConsult.state.name | translate }}
      </span>
      <app-state-progress-bar
        [flow]="[
          { name: 'PendingDeleted', notion: 'Error' },
          { name: 'Canceled', notion: 'Warning' },
          { name: 'Open' },
          { name: 'New' },
          { name: 'PendingReject', notion: 'Warning' },
          { name: 'Picked' },
          { name: 'Date' },
          { name: 'FilledIn' },
          { name: 'Done' },
          { name: 'Disapproved', notion: 'Warning' }
        ]"
        [currentState]="currentState"
      ></app-state-progress-bar>
    </div>
  </div>

  <div class="row">
    <div class="iconContainer">
      <span class="icon">
        <mat-icon>school</mat-icon>
      </span>
    </div>

    <div class="col data">
      <span class="info">{{ "COMPONENTS.TILE.SPECIALTY" | translate }}</span>
      <span>
        {{ energyConsult.specialty ? energyConsult.specialty.name : "" }}
      </span>
    </div>
  </div>

  <div class="row">
    <div class="iconContainer">
      <span class="icon">
        <mat-icon>hail</mat-icon>
      </span>
    </div>

    <div class="col data">
      <span class="info">{{ "COMPONENTS.TILE.COACH" | translate }}</span>
      <span>
        {{ energyConsult.coach ? energyConsult.coach.firstName + " " + energyConsult.coach.lastName : ("COMPONENTS.TILE.ABSENT_DATA" | translate) }}
      </span>
    </div>
  </div>

  <div class="row">
    <div class="iconContainer">
      <span class="icon">
        <mat-icon>fact_check</mat-icon>
      </span>
    </div>

    <div class="col data">
      <span class="info">{{ "COMPONENTS.TILE.DATE_REQUESTED" | translate }}</span>

      <span>
        {{ energyConsult.requestDate | localDate : "fullDate" }}
      </span>
    </div>
  </div>

  <div *ngIf="energyConsult.state.name === 'PendingDeleted'" class="row">
    <div class="iconContainer">
      <span class="icon">
        <mat-icon>auto_delete</mat-icon>
      </span>
    </div>

    <div class="col data">
      <span class="info">{{ "COMPONENTS.TILE.DATE_REQUESTED_DELETION" | translate }}</span>
      <span>
        {{ energyConsult.lastStateChangeDate! | localDate : "fullDate" }}
      </span>
    </div>
  </div>

  <div class="row">
    <div class="iconContainer">
      <span class="icon">
        <mat-icon>work_history</mat-icon>
      </span>
    </div>

    <div class="col data">
      <span class="info">{{ "COMPONENTS.TILE.DATE_PLANNED" | translate }}</span>
      <span> {{ energyConsult.appointmentDate ? (energyConsult.appointmentDate | localDate : "EEEE, d MMMM y, HH:mm") : ("COMPONENTS.TILE.ABSENT_DATA" | translate) }}</span>
    </div>
  </div>

  <div class="row">
    <div class="iconContainer">
      <span class="icon">
        <mat-icon>explore</mat-icon>
      </span>
    </div>

    <div class="col data">
      <span class="info">{{ "COMPONENTS.TILE.LOCATION" | translate }}</span>

      <span>{{ energyConsult.location }}</span>
    </div>
  </div>

  <div class="row">
    <div class="col-4 iconContainer">
      <span class="icon">
        <mat-icon>description</mat-icon>
      </span>
    </div>
    <div class="col-8 data">
      <span class="info"> {{ "COMPONENTS.TILE.EXTRA_MESSAGE" | translate }} </span>
      <span *ngIf="energyConsult.message !== 'null' && this.inspecting === true" class="">{{ energyConsult.message }}</span>
      <span *ngIf="energyConsult.message !== 'null' && this.inspecting !== true" [matTooltip]="energyConsult.message" class="text-truncate">{{ energyConsult.message }}</span>
      <span *ngIf="energyConsult.message === 'null' || energyConsult.message === ''" class="text-wrap">{{ "COMPONENTS.TILE.EMPTY_EXTRA_MESSAGE" | translate }}</span>
    </div>
  </div>

  <div class="row" *ngIf="energyConsult.isActive && energyConsultId">
    <div class="iconContainer">
      <span class="icon">
        <mat-icon>contact_page</mat-icon>
      </span>
    </div>

    <div class="col data">
      <p>
        <span class="info">{{ "COMPONENTS.TILE.CONTACT" | translate }}</span>
        <span>{{ this.energyConsult.resident?.firstName + " " + this.energyConsult.resident?.lastName }}</span>
      </p>

      <p>
        <span class="icon">
          <span class="info"> {{ "COMPONENTS.TILE.PHONE_NUMBER" | translate }} </span>
          {{ energyConsult.isActive && energyConsultId ? (!energyConsult.resident?.phoneNumber ? "-" : energyConsult.resident?.phoneNumber) : "" }}
        </span>
      </p>
      <p>
        <span class="icon">
          <span class="info"> {{ "COMPONENTS.TILE.EMAIL" | translate }} </span> {{ energyConsult.isActive && energyConsultId ? energyConsult.resident?.email : "" }}
        </span>
      </p>
    </div>
  </div>

  <div class="row" *ngIf="!checkAction(3)">
    <div class="col actionsContainer align-self-center pt-3 border-top">
      <ul class="d-flex justify-content-around">
        <li *appIsRole="['coach', 'coordinator']">
          <button
            mat-flat-button
            class="btn-block regularButton"
            [routerLink]="[applicationService.session.activeRole.name === 'coach' ? '/content/coach/request/' : '/content/coordinator/request/', energyConsult.id]"
          >
            <mat-icon>chevron_right</mat-icon>
            {{ "COMPONENTS.TILE.REQUEST_OPEN" | translate }}
          </button>
        </li>

        <li *ngIf="energyConsultCanBeCanceled">
          <button mat-icon-button class="discardButton" (click)="cancelRequestDialog(energyConsult.id)">
            <mat-icon [matTooltip]="'COMPONENTS.TILE.CANCEL_REQUEST' | translate">do_not_disturb_on</mat-icon>
          </button>
        </li>

        <li *ngIf="energyConsultCanBeDeleted">
          <button class="deleteButton" mat-icon-button (click)="deleteRequestDialog(energyConsult.id)">
            <mat-icon>delete</mat-icon>
          </button>
        </li>

        <li *ngIf="(energyConsult.state.name === 'Done' || energyConsult.state.name === 'FilledIn') && applicationService.session.activeRole.name === 'resident'">
          <button mat-flat-button class="btn-block regularButton" [routerLink]="['/content/resident/report/', energyConsult.id]">
            <mat-icon>wysiwyg</mat-icon>
            {{ "COMPONENTS.TILE.SEE_REPORT" | translate }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>

<ng-template #deleteDialog let-data="data">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md">
        <h3 class="title">{{ "COMPONENTS.TILE.REQUEST_DELETE.TITLE" | translate }}</h3>
        <p>
          {{ "COMPONENTS.TILE.REQUEST_DELETE.CONFIRM" | translate }}
          {{
            !(!checkAction(2) && energyConsult.canBeDeleted) && applicationService.session.activeRole.name === "resident"
              ? ("COMPONENTS.TILE.REQUEST_DELETE.EMAIL" | translate)
              : ""
          }}
        </p>
        <button mat-raised-button color="primary" (click)="dialogService.close()">{{ "COMPONENTS.DIALOG.CANCEL" | translate }}</button>
        <button mat-raised-button color="primary" (click)="deleteRequest(); dialogService.close()">{{ "COMPONENTS.DIALOG.SUBMIT" | translate }}</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #cancelDialog let-data="data">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md">
        <h3 class="title">{{ "COMPONENTS.TILE.REQUEST_CANCEL.TITLE" | translate }}</h3>
        <p>{{ "COMPONENTS.TILE.REQUEST_CANCEL.CONFIRM" | translate }}</p>
        <button mat-raised-button color="primary" (click)="dialogService.close()">{{ "COMPONENTS.DIALOG.BACK" | translate }}</button>
        <button mat-raised-button color="primary" (click)="cancelRequest(); dialogService.close()">{{ "COMPONENTS.DIALOG.SUBMIT" | translate }}</button>
      </div>
    </div>
  </div>
</ng-template>
