<form *ngIf="allSpecialties" [formGroup]="formGroup">
    <div class="row">
      <div class="col-12">
        <h3> {{ "FORMS.QUESTIONNAIRE.NAME" | translate }}:</h3>
        <mat-form-field appearance="outline">
          <mat-label>{{ "FORMS.QUESTIONNAIRE.NAME" | translate }}</mat-label>
          <input matInput formControlName="title" />
          <app-error [control]="formGroup.controls['title']"></app-error>
        </mat-form-field>
      </div>
    </div>
  
    <div class="row">
      <div class="col-12">
        <h3>{{ "FORMS.QUESTIONNAIRE.DESCRIPTION" | translate }}:</h3>
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>{{ "FORMS.QUESTIONNAIRE.DESCRIPTION" | translate }}</mat-label>
          <textarea matInput formControlName="description"></textarea>
          <app-error [control]="formGroup.controls['description']"></app-error>
        </mat-form-field>
      </div>
    </div>
  
    <div class="row">
      <div class="col-12">
        <h3>{{ "FORMS.QUESTIONNAIRE.SPECIALTIES" | translate }}:</h3>
        <mat-form-field appearance="outline">
          <mat-label>{{ "FORMS.QUESTIONNAIRE.SPECIALTIES" | translate }}</mat-label>
          <mat-select multiple [compareWith]="compareEntities" formControlName="specialties">
            <mat-option *ngFor="let specialty of allSpecialties" [value]="specialty">{{ specialty.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h3>{{ "FORMS.QUESTIONNAIRE.TYPE.NAME" | translate }}:</h3>
        <mat-form-field appearance="outline">
          <mat-label>{{ "FORMS.QUESTIONNAIRE.TYPE.NAME" | translate }}</mat-label>
          <mat-select [compareWith]="compareEntities" formControlName="questionnaireType">
            <mat-option *ngFor="let questionnaireType of allQuestionnaireTypes" [value]="questionnaireType">{{ "FORMS.QUESTIONNAIRE.TYPE." + questionnaireType.name | uppercase | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>