import { Component } from "@angular/core";
import { APP_ORGANISATION } from "../../../environments/environment.organisation";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.less"],
})
export class FooterComponent {
  public domainName: string = APP_ORGANISATION.SOCIALS.SLOGAN;
  public privacyURL: string = APP_ORGANISATION.SOCIALS.LINKS.PRIVACY;
  public disclaimerURL: string = APP_ORGANISATION.SOCIALS.LINKS.DISCLAIMER;
}
