import { Component, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Logic, Question } from "../../classes/flow/Questionnaire/Question";
import { Option } from "../../classes/flow/Questionnaire/Option";
import { DialogService } from "../../services/dialog.service";
import { GraphQLService } from "../../services/graphql.service";
import { QuestionService } from "../../services/question.service";
import { ReportService } from "../../services/report.service";
import { EnergyConsultService } from "../../services/energy-consult.service";
import { SnackbarService } from "../../services/snackbar.service";
import { ReportComponent } from "../report/report.component";
import { RequestStates } from "../../classes/flow/request/RequestStates";

@Component({
  selector: "app-view-report",
  templateUrl: "./view-report.component.html",
  styleUrls: ["./view-report.component.less"],
})
export class ViewReportComponent extends ReportComponent {
  @ViewChild("successfullyDisapprovedDialog")
  public successfullyDisapprovedDialog!: TemplateRef<unknown>;
  public disapprovalReason: FormControl = new FormControl("", Validators.required);

  constructor(
    router: Router,
    route: ActivatedRoute,
    graphService: GraphQLService,
    snackService: SnackbarService,
    dialogService: DialogService,
    reportService: ReportService,
    energyConsultService: EnergyConsultService,
    questionService: QuestionService,
    translateService: TranslateService
  ) {
    super(router, route, graphService, snackService, dialogService, reportService, energyConsultService, questionService, translateService);
  }

  /**
   * initial setup for the questionnaire
   */
  protected async retrieveReport() {
    if (this.energyConsult) {
      try {
        this.questionnaires = await this.reportService.getAllAnsweredQuestions(this.energyConsult.id);
        this.retrieveQuestionnaires();
      } catch (error) {
        this.snackService.open(this.translateService.instant("FORMS.REPORT.ERROR.COMPLETED"));
        this.router.navigate(["/content/resident/report", this.energyConsult.id]);
      }
    }
  }

  /**
   * Checks the compare answer with the answer
   * @param res The first answer
   * @param logic The logic of the second answer
   * @returns The result of the comparison
   */
  protected checkCompare(res: any, logic: Logic): boolean {
    switch (logic.operatorType?.operation) {
      case "===":
        return res === logic.compareAnswer;
      case "!=":
        return res != logic.compareAnswer;
      case ">":
        return parseInt(res) > parseInt(logic.compareAnswer!);
      case "<":
        return parseInt(res) < parseInt(logic.compareAnswer!);
    }
    return false;
  }

  /**
   * Gets an option of the given question
   * @param question The specific question
   * @returns The value of the particular option
   */
  public getSelectOption(question: Question): string {
    return question.options?.find((option: Option) => option.id == question.answer?.answer)?.value ?? "";
  }

  /**
   * Sends a mail
   */
  public async sendRequestToMail() {
    if (this.energyConsult) {
      try {
        await this.energyConsultService.sendPDFToResident(this.energyConsult.id);
        this.snackService.open(this.translateService.instant("FORMS.REPORT.SUCCESS.MAIL"));
      } catch (error) {
        this.snackService.open(this.translateService.instant("COMPONENTS.EVERYWHERE.ERROR.GENERIC"));
      }
    }
  }

  /**
   * Approves the current energyConsult
   */
  public async approveRequest() {
    if (this.energyConsult) {
      try {
        this.energyConsult.state.name = RequestStates.DONE;
        await this.energyConsultService.setState(this.energyConsult);
        this.snackService.open(this.translateService.instant("FORMS.REPORT.SUCCESS.COMPLETED"), "", 5000);
        this.router.navigate(["/content/resident/dashboard"]);
      } catch (error) {
        this.snackService.open(this.translateService.instant("COMPONENTS.EVERYWHERE.ERROR.GENERIC"));
      }
    }
  }

  /**
   * Disapproves the current energyConsult
   */
  public async disapproveRequest() {
    if (this.energyConsult) {
      try {
        this.energyConsult.state.name = RequestStates.DISAPPROVED;
        this.energyConsult.disapprovalReason = this.disapprovalReason.value;
        this.energyConsult.state.name = RequestStates.DISAPPROVED;
        await this.energyConsultService.disapproveEnergyConsult(this.energyConsult);

        this.dialogService.close();
        this.dialogService.open({
          template: this.successfullyDisapprovedDialog,
        });
        this.router.navigate(["/content/resident/dashboard"]);
      } catch (error) {
        this.snackService.open(this.translateService.instant("COMPONENTS.EVERYWHERE.ERROR.GENERIC"));
      }
    }
  }
}
