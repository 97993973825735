import { Injectable } from "@angular/core";
import { GraphQLService } from "./graphql.service";
import { Specialty } from "../classes/flow/Questionnaire/Specialty";

@Injectable({
  providedIn: "root",
})
export class SpecialtyService {
  public constructor(private readonly graphqlService: GraphQLService) {}

  /**
   * Gets all specialties
   * @returns A Promise related to the collection of all possible specialties
   */
  public async getSpecialties(): Promise<Specialty[]> {
    const res = await this.graphqlService.query(
      `query {
        specialties {
          value(order: {order: ASC}) {
            id
            name
            description
            order
            changes {
              lastChange {
                userId
                time
              }
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
            }
          }
          messages{
            message
          }
        }
      }`,
      true
    );
    return res.data.specialties.value.map((specialty: any) => {
      return {
        id: specialty.id,
        changes: this.graphqlService.createChangesObject(specialty.changes),
        order: specialty.order,
        name: specialty.name,
        description: specialty,
      };
    });
  }

  public async getSpecialtiesByCoach(userId: string): Promise<{ coachId: string; specialties: { id: number; specialty: Specialty }[] }> {
    const res = await this.graphqlService.query(`query initSpecialties {
          coaches {
            value (where: {userId: {eq: ${userId}}}) {
              id
              coachSpecialties {
                id
                specialty {
                  name
                  id
                }
              }
              changes {
                fullDetails {
                  key
                  value {
                    userId
                    time
                  }
                }
                lastChange {
                  userId
                  time
                }
              }
            }
            messages {
              message
            }
          }
      }`);

    if (res.data.coaches.value) {
      return {
        coachId: res.data.coaches.value[0].id,
        specialties: res.data.coaches.value[0].coachSpecialties.map(function (element: { id: number; specialty: Specialty }) {
          return {
            id: element.id,
            specialty: element.specialty,
          };
        }),
      };
    } else {
      return { coachId: "", specialties: [] };
    }
  }

  public async addSpecialtyForCoach(coachId: string, specialtyId: number) {
    await this.graphqlService.query(`mutation addCoachSpecialty{
      addCoachSpecialty(coachSpecialty: {coachId: ${coachId}, specialtyId: ${specialtyId}}) {
        value {
          id
        }
        messages {
          message
        }
      }
    }
      `);
  }

  public async removeSpecialtyForCoach(coachSpecialtyId: number) {
    await this.graphqlService.query(`mutation DeleteSpeciality {
        deleteCoachSpecialty(coachSpecialtyId: ${coachSpecialtyId}) {
          messages {
            message
          }
        }
      }`);
  }
}
