<div id="requestContainer" class="container" *ngIf="energyConsult">
  <div class="row">
    <div id="actionsContainer" class="col-md">
      <span>
        <button mat-flat-button [routerLink]="['../../']">
          <mat-icon>arrow_back_ios_new</mat-icon>
          <span>{{ "PAGES.BACK" | translate }}</span>
        </button>
      </span>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-lg-6 col-xl-4">
      <app-energy-consult [energyConsult]="energyConsult" [actions]="3" [inspecting]="true"></app-energy-consult>
    </div>

    <div class="col-md-12 col-lg-6 col-xl-8 align-self-center">
      <div class="row">
        <div id="alertContainer" class="col-md" *ngIf="hasAlert">
          <span class="alert" *ngIf="energyConsult.state.name === 'PendingReject'">
            <h2>{{ "COMPONENTS.COACH_REQUEST.ALERT.PENDING_REJECTED.TITLE" | translate }}</h2>
            <div *ngIf="energyConsult.coach && energyConsult.rejectReason; else noRejectReason">
              <p>{{ "COMPONENTS.COACH_REQUEST.ALERT.PENDING_REJECTED.DESCRIPTION_WITH_REASON" | translate : { coachName: energyConsult.coach.fullName } }}:</p>
              <p>{{ energyConsult.rejectReason }}</p>
            </div>

            <ng-template #noRejectReason>
              <p>{{ "COMPONENTS.COACH_REQUEST.ALERT.PENDING_REJECTED.DESCRIPTION_WITHOUT_REASON" | translate : { coachName: energyConsult.coach?.fullName } }}</p>
            </ng-template>

            <div *appIsRole="'coordinator'">
              <button mat-stroked-button (click)="dialog.show(recoverRequestDialog)">{{ "COMPONENTS.COACH_REQUEST.ALERT.RENEW_BUTTON" | translate }}</button>
              <button mat-stroked-button (click)="dialog.show(deleteRequestDialog)">{{ "FORMS.DELETE" | translate }}</button>
            </div>
          </span>

          <span class="alert" *ngIf="energyConsult.state.name === 'Disapproved'">
            <h2>{{ "COMPONENTS.COACH_REQUEST.ALERT.DISAPPROVED.TITLE" | translate }}</h2>
            <p>
              {{
                "COMPONENTS.COACH_REQUEST.ALERT.DISAPPROVED.DESCRIPTION"
                  | translate : { residentName: energyConsult.resident?.firstName + " " + energyConsult.resident?.lastName }
              }}:
            </p>
            <p>{{ energyConsult.disapprovalReason }}</p>
          </span>
        </div>
      </div>

      <div class="row">
        <ng-container *appIsRole="'coordinator'">
          <div class="col-md-12 col-lg-6" *ngIf="energyConsult.canCoachBeChanged">
            <div class="row">
              <div class="col-md action">
                <div class="row">
                  <div class="col-md">
                    <mat-icon>hail</mat-icon>
                    <label class="d-block">{{ "COMPONENTS.COACH_REQUEST.CHANGE_COACH.INFO" | translate }}</label>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md">
                    <button mat-flat-button class="btn-block primary" (click)="this.changeCoachClickHandler(changeCoachDialog)">
                      <span>{{ "COMPONENTS.COACH_REQUEST.CHANGE_COACH.NAME" | translate }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <div id="editContainer" class="col-md" *ngIf="!energyConsult.canBeClaimed; else claimTemplate">
          <div class="row">
            <div class="col-md"></div>
          </div>

          <div class="row">
            <ng-container *appIsRole="'coach'">
              <div class="col-md-12 col-lg-6" *ngIf="energyConsult.canBeChanged">
                <!--hier komt die check voor coach-->
                <div class="row">
                  <div class="col-md action">
                    <div class="row">
                      <div class="iconContainer">
                        <span class="icon">
                          <mat-icon>work_history</mat-icon>
                        </span>
                      </div>

                      <div class="col data">
                        <span class="info">{{ "COMPONENTS.TILE.DATE_PLANNED" | translate }}</span>
                        <span> {{ energyConsult.appointmentDate ? (energyConsult.appointmentDate | localDate : "EEEE, d MMMM y, HH:mm") : "" }} </span>
                      </div>
                    </div>

                    <div class="row" *ngIf="isEditingDate">
                      <div class="col-md">
                        <form [formGroup]="formGroup">
                          <div class="row">
                            <div class="col-12">
                              <mat-form-field appearance="outline" class="w-75 mt-2">
                                <mat-label>{{ "COMPONENTS.COACH_REQUEST.DATE" | translate }}</mat-label>
                                <input matInput [min]="energyConsult.minimalAppointmentDate" [matDatepicker]="datepicker" formControlName="date" />
                                <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                                <mat-datepicker #datepicker></mat-datepicker>
                              </mat-form-field>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <mat-form-field appearance="outline" class="w-75">
                                <mat-label>{{ "COMPONENTS.COACH_REQUEST.TIME" | translate }}</mat-label>
                                <div class="d-flex align-content-center justify-content-between h-0">
                                  <input matInput [ngxTimepicker]="time" [format]="24" formControlName="time" />
                                  <ngx-material-timepicker-toggle matSuffix [for]="time"></ngx-material-timepicker-toggle>
                                  <ngx-material-timepicker #time [enableKeyboardInput]="true"></ngx-material-timepicker>
                                </div>
                              </mat-form-field>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md" *ngIf="!isEditingDate; else editDate">
                        <button mat-flat-button class="btn-block primary" (click)="setEditingDate(true)">
                          <span>{{ "COMPONENTS.COACH_REQUEST.EDIT_DATE_TIME_BUTTON" | translate }}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <ng-template #editDate>
                  <div class="col-md">
                    <button mat-flat-button class="btn-block primary" [disabled]="formGroup.invalid" (click)="saveDate()">
                      <span>{{ "FORMS.SAVE" | translate }}</span>
                    </button>
                  </div>
                </ng-template>
              </div>
            </ng-container>

            <div class="col-md-12 col-lg-6" *ngIf="energyConsult.canBeRejected">
              <div class="row">
                <div class="col-md action">
                  <div class="row">
                    <div class="col-md">
                      <mat-icon>cancel</mat-icon>
                      <label class="d-block">{{ "COMPONENTS.COACH_REQUEST.REJECT.INFO" | translate }}</label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md">
                      <button mat-flat-button class="btn-block primary" (click)="dialog.show(reject)">
                        <span [matTooltip]="'COMPONENTS.COACH_REQUEST.REJECT.DESCRIPTION' | translate">{{ "COMPONENTS.COACH_REQUEST.REJECT.NAME" | translate }}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ng-container *appIsRole="'coach'">
              <div class="col-md-12 col-lg-6" *ngIf="energyConsult.state.name === 'Date'">
                <div class="row">
                  <div class="col-md action">
                    <div class="row">
                      <div class="col-md">
                        <mat-icon>done_all</mat-icon>
                        <label class="d-block">{{ "COMPONENTS.COACH_REQUEST.FILL_IN_REPORT" | translate }}</label>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md">
                        <div class="row" *ngIf="energyConsult.state.name === 'Date'">
                          <div class="col-md">
                            <button
                              class="btn-block primary"
                              mat-flat-button
                              routerLink="report"
                              [matTooltip]="'COMPONENTS.COACH_REQUEST.FILL_IN_REPORT_DESCRIPTION' | translate"
                            >
                              {{ "COMPONENTS.COACH_REQUEST.FILL_IN_REPORT" | translate }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

            <div class="col-md-12 col-lg-6" *ngIf="energyConsult.state.name === 'Done' || energyConsult.state.name === 'FilledIn'">
              <div class="row">
                <div class="col-md action">
                  <div class="row">
                    <div class="col-md">
                      <mat-icon>picture_as_pdf</mat-icon>
                      <label>{{ "COMPONENTS.COACH_REQUEST.DONE.DESCRIPTION" | translate }}</label>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md">
                      <button mat-flat-button class="btn-block primary mb-1" (click)="downloadReportAsPDF()">
                        {{ "COMPONENTS.DIALOG.DOWNLOAD_PDF" | translate }} <mat-icon [ngStyle]="{ color: 'white' }">download</mat-icon>
                      </button>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md">
                      <button mat-flat-button class="btn-block primary" (click)="sendPDFMail()">
                        <span>{{ "COMPONENTS.COACH_REQUEST.DONE.SEND_REPORT_BUTTON" | translate }}</span>
                      </button>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md" *ngIf="pdfSendDate">
                      {{ "COMPONENTS.COACH_REQUEST.DONE.REPORT_WAS_SEND" | translate }}
                    </div>
                  </div>
                  <div class="row" *ngIf="sendDateAvailable">
                    <div class="col-md">
                      {{ pdfSendDate ? (pdfSendDate | localDate : "EEEE, d MMMM y, HH:mm") : ("COMPONENTS.COACH_REQUEST.DONE.NOT_SEND_YET" | translate) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ng-template #claimTemplate>
          <div *appIsRole="'coach'" class="row">
            <div class="col-md action">
              <div class="row">
                <div class="col-md d-flex">
                  <mat-icon>done_all</mat-icon>
                  <label>{{ "COMPONENTS.COACH_REQUEST.CLAIM.DESCRIPTION" | translate }}</label>
                </div>
              </div>

              <div class="row">
                <div class="col-md">
                  <button mat-flat-button class="btn-block primary" (click)="claimEnergyConsult()">
                    <mat-icon>chevron_right</mat-icon>
                    <span>{{ "COMPONENTS.COACH_REQUEST.CLAIM.ACCEPT_BUTTON" | translate }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<ng-template #pdfGenerateSuccess>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md">
        <h3 class="title">{{ "COMPONENTS.COACH_REQUEST.DIALOGS.PDF.SUCCESS_MESSAGE" | translate }}</h3>
        <br />
        <img src="https://c.tenor.com/q-zZSTX6jSIAAAAC/mail-download.gif" /><br />
      </div>
    </div>
  </div>
</ng-template>

<ng-template #reject>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md">
        <h3 class="title">{{ "COMPONENTS.COACH_REQUEST.DIALOGS.REQUEST_REJECTION.TITLE" | translate }}</h3>
        <p>{{ "COMPONENTS.COACH_REQUEST.DIALOGS.REQUEST_REJECTION.DESCRIPTION" | translate }}</p>
        <mat-form-field class="example-full-width w-100" appearance="fill">
          <mat-label>{{ "COMPONENTS.COACH_REQUEST.DIALOGS.REQUEST_REJECTION.REASON.NAME" | translate }}</mat-label>
          <textarea
            matInput
            placeholder="{{ 'COMPONENTS.COACH_REQUEST.DIALOGS.REQUEST_REJECTION.REASON.PLACEHOLDER' | translate }}"
            [formControl]="rejectReasonControl"
          ></textarea>
          <app-error [control]="rejectReasonControl"></app-error>
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="dialog.close()">{{ "COMPONENTS.DIALOG.BACK" | translate }}</button>
        <button mat-raised-button color="primary" (click)="rejectEnergyConsult()">{{ "COMPONENTS.DIALOG.SUBMIT" | translate }}</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #recoverRequestDialog>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md">
        <h3 class="title">{{ "COMPONENTS.COACH_REQUEST.DIALOGS.REQUEST_RENEWAL.TITLE" | translate }}</h3>
        <p>{{ "COMPONENTS.COACH_REQUEST.DIALOGS.REQUEST_RENEWAL.DESCRIPTION" | translate }}</p>
        <button mat-raised-button color="primary" (click)="dialog.close()">{{ "COMPONENTS.DIALOG.BACK" | translate }}</button>
        <button mat-raised-button color="primary" (click)="recoverEnergyConsult(); dialog.close()">{{ "COMPONENTS.DIALOG.SUBMIT" | translate }}</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #deleteRequestDialog>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md">
        <h3 class="title">{{ "COMPONENTS.COACH_REQUEST.DIALOGS.DELETION.TITLE" | translate }}</h3>
        <p>{{ "COMPONENTS.COACH_REQUEST.DIALOGS.DELETION.DESCRIPTION" | translate }}</p>
        <button mat-raised-button color="primary" (click)="dialog.close()">{{ "COMPONENTS.DIALOG.BACK" | translate }}</button>
        <button mat-raised-button color="primary" (click)="deleteEnergyConsult(); dialog.close()">{{ "COMPONENTS.DIALOG.SUBMIT" | translate }}</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #changeCoachDialog>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md">
        <h3 class="title">{{ "COMPONENTS.COACH_REQUEST.DIALOGS.CHANGE_COACH.TITLE" | translate }}</h3>
        <p>{{ "COMPONENTS.COACH_REQUEST.DIALOGS.CHANGE_COACH.DESCRIPTION" | translate }}</p>

        <div class="d-block">
          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>{{ "COMPONENTS.COACH_REQUEST.DIALOGS.CHANGE_COACH.LABEL" | translate }}</mat-label>
            <input
              type="text"
              placeholder="{{ 'COMPONENTS.COACH_REQUEST.DIALOGS.CHANGE_COACH.PLACEHOLDER' | translate }}"
              matInput
              [formControl]="changeCoachControl"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCoachName" #coachInput>
              <mat-option *ngFor="let coach of coaches" [value]="coach"> {{ coach.firstName }} {{ coach.lastName }} </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <button mat-raised-button color="primary" (click)="dialog.close()">{{ "COMPONENTS.DIALOG.BACK" | translate }}</button>
        <button mat-raised-button color="primary" (click)="assignCoach(changeCoachControl.value)">{{ "COMPONENTS.DIALOG.SUBMIT" | translate }}</button>
      </div>
    </div>
  </div>
</ng-template>
