<div style="color: var(--brand-color)" class="container justify-content-center">
  <h3>
    <div class="row">
      <div class="col-md">Oh no! Something went wrong :(</div>
    </div>

    <div class="row">
      <div class="col-md">
        <div class="row">
          <div class="col-md">
            <span>Reason: </span>
          </div>
        </div>

        <div class="row">
          <div class="col-md">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </h3>
</div>
