import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ApplicationService } from "../../../services/application.service";
import { DialogService } from "../../../services/dialog.service";
import { SnackbarService } from "../../../services/snackbar.service";
import { Resident } from "../../../classes/flow/session/impl/Resident";
import { phoneNumberValidator } from "../../../validators/phoneNumber";
import { postalCodeValidator } from "../../../validators/postalCode";
import { Specialty } from "../../../classes/flow/Questionnaire/Specialty";
import { TranslateService } from "@ngx-translate/core";
import { alphaValidator } from "../../../validators/alpha";
import { SpecialtyService } from "../../../services/specialty.service";
import { houseNumberValidator } from "../../../validators/houseNumber";
import { UserService } from "../../../services/user.service";
import { EnergyConsult } from "../../../classes/flow/request/EnergyConsult";
import { EnergyConsultService } from "../../../services/energy-consult.service";
import { RequestStates } from "../../../classes/flow/request/RequestStates";

@Component({
  selector: "app-new-energy-consult",
  templateUrl: "./new-energy-consult.component.html",
  styleUrls: ["./new-energy-consult.component.less"],
})
export class NewEnergyConsultComponent implements OnInit {
  public allSpecialties: Specialty[] = [];
  public formGroup: FormGroup = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.email]),
    firstName: new FormControl("", [Validators.required, alphaValidator]),
    lastName: new FormControl("", [Validators.required, alphaValidator]),
    phoneNumber: new FormControl("", [Validators.required, phoneNumberValidator]),
    postalCode: new FormControl("", [Validators.required, postalCodeValidator]),
    houseNumber: new FormControl("", [Validators.required, houseNumberValidator]),
    houseNumberSuffix: new FormControl("", [alphaValidator, Validators.maxLength(5)]),
    specialties: new FormControl(),
    extraMessage: new FormControl("", Validators.maxLength(250)),
  });

  @ViewChild("popup") popup!: TemplateRef<unknown>;

  public constructor(
    private readonly energyConsultService: EnergyConsultService,
    public readonly applicationService: ApplicationService,
    public readonly userService: UserService,
    public readonly router: Router,
    public readonly dialogService: DialogService,
    public readonly snackService: SnackbarService,
    private readonly specialtyService: SpecialtyService,
    private readonly translateService: TranslateService
  ) {}

  async ngOnInit() {
    await this.initForm();
  }

  /**
   *Initializes the form
   */
  public async initForm() {
    this.allSpecialties = await this.specialtyService.getSpecialties();
    this.formGroup.patchValue({
      specialties: this.allSpecialties.find((specialty) => specialty.order == 0),
    });

    if (this.applicationService.session.user instanceof Resident) {
      this.initializeFormData(this.applicationService.session.user);
    }
  }

  /**
   * Saves a new form
   */
  public async saveNewEnergyConsultForm() {
    const data: any = this.formGroup.value;
    this.formGroup.disable();
    const resident: Resident = new Resident({ id: 0, email: data.email, firstName: data.firstName, lastName: data.lastName, phoneNumber: data.phoneNumber });
    const energyConsult: EnergyConsult = new EnergyConsult(
      0,
      data.extraMessage,
      data.postalCode,
      data.houseNumber,
      new Date(),
      { name: RequestStates.NEW },
      data.specialties,
      resident,
      data.houseNumberSuffix
    );
    const result = await this.energyConsultService.create(energyConsult);
    if (result.data.addEnergyConsult.messages.length == 0) {
      this.snackService.open(this.translateService.instant("FORMS.NEW_ENERGY_CONSULT.SUCCESS_MESSAGE"));
      this.formGroup.reset();
      if (this.applicationService.session.authenticated) {
        this.applicationService.session.user = await this.userService.initialize(this.applicationService.session.activeRole);
        this.router.navigate(["/content"]);
      } else {
        this.openSuccessDialog();
      }
    }
  }

  /**
   * Opens the success dialog
   */
  openSuccessDialog() {
    this.dialogService.open({
      template: this.popup,
    });
  }

  /**
   * Initializes the form
   * @param {Resident} user - the resident which the form gets data from
   */
  public initializeFormData(user: Resident) {
    this.formGroup.patchValue({
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
      postalCode: user.postalCode,
      houseNumber: user.houseNumber,
      houseNumberSuffix: user.houseNumberSuffix,
    });
  }
}
