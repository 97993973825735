<div *ngFor="let filterGroup of filters" id="filterContainer" class="d-flex p-1 flex-wrap justify-content-center ms-auto">
  <ng-container *ngFor="let filter of filterGroup.filters">
    <button
      (click)="activateFilter(filter, filterGroup)"
      class="mx-2 badge"
      mat-stroked-button
      [matBadge]="filter.amount"
      matBadgePosition="after"
      matBadgeColor="accent"
      [ngClass]="{'active': filterGroup.activeFilters?.includes(filter)}"
      [matTooltip]="filterGroup.description"
    >
      {{filter.title}}
    </button>
  </ng-container>
</div>

<!-- *ngIf="filter.amount > 0  && filter.amount !== filterGroup.requestAmount" -->
